import { useState, useEffect, useRef } from "react";
import { apiRainmakersV2 } from "services/apiService";
import { useUser } from "global/UserContext";

export interface Event {
  id: string;
  title: string;
  description: string;
  duration: string;
  initDatetime: string;
  endDatetime: string;
  isOnline: boolean;
  roomId: number | null;
  location: string;
  totalParticipants: number;
  date: string;
  scheduleRuleDescription: string;
  coordinator: {
    uuid: string;
    firstName: string;
    lastName: string;
    photoUrl?: string;
  } | null;
  presenters: {
    uuid: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  }[];
  curators: {
    uuid: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  }[];
  time: string;
  type: {
    name: string;
    code: string;
    isPrivate: boolean;
  };
  status: "PENDING" | "ACCEPTED" | "DECLINED" | "PARTIAL";
  room?: {
    id: number;
    title: string;
  };
}

export const useNextEvents = () => {
  const { user } = useUser();
  const organizationId = user?.user_to_organization[0]?.organization?.uuid;
  const userId = user?.id || "";

  const [nextEvents, setNextEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const fetchNextEvents = async () => {
    if (!organizationId || !userId) return;

    setLoading(true);
    setError(null);

    try {
      const currentDateISO = new Date().toISOString();

      const response = await apiRainmakersV2.get(`/events/v1/events/next`, {
        params: {
          user_uuid: userId,
          init_datetime: currentDateISO,
        },
      });
      const rawEvents = response.data;

      const processedEvents = rawEvents.map((event: any) => {
        const eventInitDate = new Date(event.init_datetime);
        const eventEndDate = new Date(event.end_datetime);
        const currentDate = new Date();

        const isOngoing =
          eventInitDate <= currentDate && eventEndDate >= currentDate;

        const duration = calculateDuration(
          event.init_datetime,
          event.end_datetime,
        );
        const time = adjustTimeToUTCMinus3(event.init_datetime);

        const coordinator = event.coordinator
          ? {
              uuid: event.coordinator.user_uuid,
              firstName: event.coordinator.first_name,
              lastName: event.coordinator.last_name,
              photoUrl: event.coordinator.photo_url || null,
            }
          : null;

        const presenters =
          event.presenters?.map((presenter: any) => ({
            uuid: presenter.user_uuid,
            firstName: presenter.first_name,
            lastName: presenter.last_name,
            photoUrl: presenter.photo_url || null,
          })) || [];

        const curators =
          event.curators?.map((curator: any) => ({
            uuid: curator.user_uuid,
            firstName: curator.first_name,
            lastName: curator.last_name,
            photoUrl: curator.photo_url || null,
          })) || [];

        return {
          id: event.id,
          title: event.name,
          description: event.description,
          duration,
          initDatetime: event.init_datetime,
          endDatetime: event.end_datetime,
          isOnline: event.is_online,
          roomId: event.room_id,
          location: event.is_online
            ? `Virtual: Sala ${event?.room_title || "Virtual"}`
            : `Presencial: ${event?.location}` || "Presencial",
          totalParticipants: event.total_participants || 0,
          date: eventInitDate.toLocaleDateString("pt-BR"),
          scheduleRuleDescription: event.schedule_rule_description,
          coordinator,
          presenters,
          curators,
          time,
          type: {
            name: event.type.name,
            code: event.type.code,
            isPrivate: event.type.is_private,
          },
          status: event.user_participant_status.status,
          isOngoing,
          room: event?.room_title
            ? {
                id: event?.room_id,
                title: event?.room_title,
              }
            : undefined,
        };
      });

      setNextEvents(processedEvents);
    } catch (err) {
      console.error("Erro ao buscar eventos:", err);
      setError("Erro ao carregar eventos. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const adjustTimeToUTCMinus3 = (utcDateTime: string): string => {
    const date = new Date(utcDateTime);
    date.setHours(date.getUTCHours() - 3);
    return date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const calculateDuration = (start: string, end: string): string => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const totalMinutes = Math.floor((endTime - startTime) / 60000);

    if (totalMinutes < 60) {
      return `${totalMinutes} min.`;
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return minutes === 0
      ? `${hours} hora${hours > 1 ? "s" : ""}`
      : `${hours} hora${hours > 1 ? "s" : ""} e ${minutes} min`;
  };

  const refreshEvents = async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(async () => {
      await fetchNextEvents();
      setIsRefreshing(false);
    }, 500);
  };

  useEffect(() => {
    if (organizationId && userId) {
      fetchNextEvents();
    }
    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [organizationId, userId]);

  return {
    nextEvents,
    loading,
    error,
    refreshEvents,
  };
};
