import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";
import { AvatarDefault } from "utils/utilsIcons";

const imageCache = new Map<string, string>();

const useUserImage = (userId: string | null) => {
  const [userImage, setUserImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUserImage = async (userId: string) => {
    if (imageCache.has(userId)) {
      setUserImage(imageCache.get(userId) || AvatarDefault);
      return;
    }

    try {
      setLoadingImage(true);
      const response = await apiOrchestrator.get(
        `/users-person/get-image/${userId}`,
      );
      const imageUrl = response.data;
      imageCache.set(userId, imageUrl);
      if (response.data) {
        setUserImage(imageUrl);
      }
    } catch (error) {
      console.error("Error fetching user image", error);
      setUserImage(AvatarDefault);
      setError("Erro ao buscar a imagem do usuário.");
    } finally {
      setLoadingImage(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserImage(userId);
    }
  }, [userId]);

  return {
    userImage,
    loadingImage,
    error,
  };
};

export default useUserImage;
