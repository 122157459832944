import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { TheRainmakersNew } from "utils/utilsIcons";
import Spin from "components/Spin";
import Text from "components/Text";
import Button from "components/Button";
import useLogin from "./useLogin";
import theme from "styles/theme";
import ChangePasswordModal from "components/ChangePasswordModal";
import SuccessModal from "pages/Register/components/SuccessModal";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import * as S from "./styles";
import WhatsappFloatingButton from "components/WhatsappFloatingButton";

export const Login: React.FC = () => {
  const {
    control,
    errors,
    loading,
    handleSubmit,
    onSubmit,
    showChangePasswordModal,
    session,
    email,
    setShowChangePasswordModal,
  } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inviteeName, setInviteeName] = useState<string | null>(null);

  useEffect(() => {
    const success = localStorage.getItem("isSuccessFinal");
    const storedInviteeName = localStorage.getItem("inviteeName");
    if (success === "true") {
      setInviteeName(storedInviteeName);
      setShowSuccessModal(true);
      localStorage.removeItem("isSuccessFinal");
      localStorage.removeItem("inviteeName");
    }
  }, []);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRememberMe(event.target.checked);
  };

  const navigateToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const navigateToRegister = () => {
    navigate("/register");
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <S.Card>
            <S.Image
              src={
                isMobile
                  ? TheRainmakersNew
                  : isTablet
                    ? TheRainmakersNew
                    : TheRainmakersNew
              }
              alt="Imagem Principal Rainmakers"
            />
          </S.Card>

          <S.FormContainer>
            {/*  {(!isMobile || !isTablet) && (
              <S.ImageLogoMark src={LogoMark} alt="LogoMark Rainmakers" />
            )} */}

            <S.StyledForm onSubmit={handleSubmit(onSubmit)}>
              {(isMobile || isTablet || true) && (
                <>
                  <Text size="xLarge" className="text-title">
                    Já possui uma conta?
                  </Text>
                </>
              )}
              <S.ContentCenter>
                <Text size="small" color="gray" className="text-form">
                  Entre com e-mail e senha
                </Text>

                <S.InputsContainer>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        size={isMobile ? "small" : "medium"}
                      />
                    )}
                  />

                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Senha"
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        size={isMobile ? "small" : "medium"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </S.InputsContainer>

                <S.TopButton>
                  <S.RememberForgotContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": {
                              color: theme.COLORS.PRIMARY,
                            },
                          }}
                          size="medium"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                      label="Lembrar-me"
                      style={{ color: theme.COLORS.TEXT_PRIMARY }}
                    />

                    <Button
                      type="button"
                      title="Esqueceu sua senha?"
                      size="medium"
                      variant="minimal"
                      onClick={navigateToForgotPassword}
                    >
                      Esqueceu sua senha?
                    </Button>
                  </S.RememberForgotContainer>
                  <Button
                    type="submit"
                    variant="primary"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {loading ? "Carregando..." : "Entrar"}
                  </Button>
                </S.TopButton>
              </S.ContentCenter>

              <S.FooterButton>
                {/*  <S.Separator>
                  <span>Não tem uma conta?</span>
                </S.Separator>
                <Button
                  type="button"
                  title="Registrar-se"
                  size="medium"
                  variant="primary"
                  onClick={navigateToRegister}
                  style={{
                    width: "10rem",
                    alignSelf: "center",
                    justifyContent: "center",
                  }}
                >
                  Registrar-se
                </Button> */}
              </S.FooterButton>
            </S.StyledForm>
          </S.FormContainer>

          {loading && <Spin size="large" fullscreen />}
        </S.Content>

        {showChangePasswordModal && (
          <ChangePasswordModal
            open={showChangePasswordModal}
            onClose={() => setShowChangePasswordModal(false)}
            session={session || ""}
            email={email}
          />
        )}

        {showSuccessModal && (
          <SuccessModal
            isOpen={showSuccessModal}
            onClose={handleCloseModal}
            inviteeName={inviteeName}
          />
        )}
      </S.Container>
      <WhatsappFloatingButton/>
    </S.Background>
  );
};
