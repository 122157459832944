import styled from "styled-components";

export interface IFullScreen {
  open: boolean;
}

export const Container = styled.div<IFullScreen>`
  display: flex;
  width: ${({ open }) => (open ? "120px" : "0")};
  height: calc(100% - 5rem);
  top: 5rem;
  left: ${({ open }) => (open ? "9rem" : "2.75rem")};
  z-index: 101;
  position: absolute;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.COLORS.BLACK};

  .header {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }
`;