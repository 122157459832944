import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Zoom,
} from "@mui/material";
import Button from "components/Button";
import TabsCustom from "components/Tabs";
import EventDataTab from "./EventTabs/EventDataTab";
import LocationTab from "./EventTabs/LocationTab";
import ScheduleTab from "./EventTabs/ScheduleTab";
import ParticipantTab from "./EventTabs/ParticipantTab";
import AdvancedConfigTab from "./EventTabs/AdvancedConfigTab";
import { useUser } from "global/UserContext";
import { apiRainmakersV2 } from "services/apiService";
import { useToast } from "hooks/useToast";
import { Event } from "../types/events";
import theme from "styles/theme";

interface ICreateEventModal {
  open: boolean;
  onClose: () => void;
  onEventCreated: (groupId: string) => void;
  onUpdate: () => void;
  isEditMode?: boolean;
  eventId?: string;
  event?: Event;
}

const CreateEventModal: React.FC<ICreateEventModal> = ({
  open,
  onClose,
  onEventCreated,
  onUpdate,
  isEditMode = false,
  eventId,
  event,
}) => {
  const [activeTab, setActiveTab] = useState("tab_event_data");
  const [tabValidations, setTabValidations] = useState<Record<string, boolean>>(
    {
      tab_event_data: isEditMode,
      tab_local: isEditMode,
      tab_scheduled: isEditMode,
      tab_participants: isEditMode,
      tab_advanced_settings: isEditMode,
    },
  );

  const handleNextTab = (currentTab: string, nextTab: string) => {
    setTabValidations((prev) => ({ ...prev, [currentTab]: true }));
    setActiveTab(nextTab);
  };

  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const { toastSuccess, toastError, toastInfo } = useToast();
  const { user } = useUser();
  const userId = user?.id;

  const [eventData, setEventData] = useState<Event>(
    event || {
      id: "",
      author_uuid: userId || "",
      name: "",
      description: "",
      thumbnail: "",
      is_exclusive: false,
      is_active: true,
      local: {
        is_online: false,
        room_id: 0,
        location: "",
      },
      schedule: null,
      participants: {
        participants: [],
        total: 0,
        total_accepted: 0,
        total_declined: 0,
        total_pending: 0,
      },
    },
  );

  const [scheduleData, setScheduleData] = useState(event?.schedule || null);

  useEffect(() => {
    if (event) {
      setEventData(event);
      if (event.schedule) {
        setScheduleData(event.schedule);
      }
    }
  }, [event]);

  useEffect(() => {
    if (isEditMode && eventId) {
      const fetchEventData = async () => {
        try {
          const response = await apiRainmakersV2.get(
            `/events/v1/events/${eventId}`,
          );
          const {
            name,
            description,
            type_code,
            is_exclusive,
            local,
            schedule,
            participants,
          } = response.data;

          setEventData({
            id: eventId,
            author_uuid: userId || "",
            name,
            description,
            type_code,
            thumbnail: response.data.thumbnail || "",
            is_exclusive: is_exclusive,
            is_active: response.data.is_active || true,
            local: local || { is_online: false, room_id: 0, location: "" },
            schedule: schedule || null,
            participants: participants || {
              participants: [],
              total: 0,
              total_accepted: 0,
              total_declined: 0,
              total_pending: 0,
            },
          });

          setEventData(response.data);
          setScheduleData(response.data.schedule || null);
        } catch (error) {
          toastError("Erro ao carregar os dados do evento.");
        }
      };
      fetchEventData();
    }
  }, [isEditMode, eventId]);

  useEffect(() => {
    if (isEditMode && event) {
      if (event.schedule) {
        setScheduleData(event.schedule);
      }
    }
  }, [isEditMode, event]);

  const saveEventData = async (
    updatedData: Partial<typeof eventData>,
  ): Promise<string | null> => {
    try {
      const payload = {
        ...eventData,
        ...updatedData,
        author_uuid: userId,
      };

      if (eventData.id) {
        const response = await apiRainmakersV2.patch(
          `/events/v1/events/${eventData.id}`,
          payload,
        );
        setEventData({
          ...eventData,
          ...updatedData,
          id: eventData.id,
        });
        return response.data.id;
      } else {
        const response = await apiRainmakersV2.post(
          "/events/v1/events/",
          payload,
        );
        setEventData({
          ...eventData,
          ...updatedData,
          id: response.data.id,
        });
        onUpdate();
        return response.data.id;
      }
    } catch (error) {
      toastError("Erro ao salvar os dados do evento.");
      return null;
    }
  };

  const updateTabValidation = (
    tab: keyof typeof tabValidations,
    isValid: boolean,
  ) => {
    setTabValidations((prev) => ({ ...prev, [tab]: isValid }));
  };

  const handleCreateEvent = async (values: {
    name: string;
    description: string;
    type_code: string;
    isExclusive: boolean;
  }): Promise<string | null> => {
    try {
      const payload = {
        author_uuid: userId,
        name: values.name,
        description: values.description,
        is_exclusive: values.isExclusive,
        is_active: true,
        type_code: values.type_code,
      };

      const response = await apiRainmakersV2.post(
        "/events/v1/events/",
        payload,
      );
      console.log("Evento criado com sucesso:", response.data);

      return response.data.id;
    } catch (error) {
      console.error("Erro ao criar o evento:", error);
      return null;
    }
  };

  const handleCancel = () => {
    if (eventData.id && !isEditMode) {
      setConfirmCancelOpen(true);
    } else {
      resetForm();
      onClose();
      onUpdate();
    }
  };

  const handleConfirmCancel = async () => {
    setConfirmCancelOpen(false);
    if (eventData.id) {
      try {
        await apiRainmakersV2.delete(`/events/v1/events/${eventData.id}`);
        toastInfo("Evento cancelado com sucesso.");
      } catch (error) {
        toastError("Erro ao cancelar o evento.");
      }
    }
    resetForm();
    onClose();
    onUpdate();
  };

  const resetForm = () => {
    setActiveTab("tab_event_data");
    setEventData({
      id: "",
      author_uuid: userId || "",
      name: "",
      description: "",
      type_code: "",
      thumbnail: "",
      is_exclusive: false,
      is_active: true,
      local: {
        is_online: false,
        room_id: 0,
        location: "",
      },
      schedule: null,
      participants: {
        participants: [],
        total: 0,
        total_accepted: 0,
        total_declined: 0,
        total_pending: 0,
      },
    });
    setTabValidations({
      eventData: false,
      location: false,
      schedule: false,
      participants: false,
      advancedConfig: false,
    });
  };

  const submitSchedule = async (payload: any) => {
    try {
      const response = await apiRainmakersV2.post(
        `/events/v1/schedule-rule/${eventData.id}`,
        payload,
      );
      toastSuccess("Agendamento salvo com sucesso!");
      setScheduleData(payload);
      handleNextTab("tab_scheduled", "tab_participants");
    } catch (error) {
      console.error("Erro ao salvar o agendamento:", error);
      toastError("Erro ao salvar o agendamento.");
    }
  };

  const resetModal = () => {
    resetForm();
  };

  const handleFinalizeCreation = () => {
    toastSuccess("Evento criado com sucesso!");
    resetModal();
    onClose();
    onUpdate();
  };

  const labels: { label: string; key: keyof typeof tabValidations }[] = [
    { label: "Escopo", key: "tab_event_data" },
    { label: "Local", key: "tab_local" },
    { label: "Agenda", key: "tab_scheduled" },
    { label: "Participantes", key: "tab_participants" },
    { label: "Configurações", key: "tab_advanced_settings" },
  ];

  const content = [
    <EventDataTab
      data={eventData}
      isEditMode={isEditMode}
      onNext={async (values) => {
        const eventId = await saveEventData(values);
        if (eventId) {
          handleNextTab("tab_event_data", "tab_local");
          toastSuccess("Dados do evento salvos com sucesso!");
        }
      }}
      onCancel={handleCancel}
    />,
    <LocationTab
      data={eventData.local}
      eventId={eventData.id}
      isEditMode={isEditMode}
      onNext={(updatedLocal) => {
        setEventData((prev) => ({
          ...prev,
          ...updatedLocal,
        }));
        handleNextTab("tab_local", "tab_scheduled");
      }}
      onCancel={handleCancel}
    />,
    <ScheduleTab
      eventId={eventData.id}
      scheduleData={scheduleData}
      onNext={(schedule) => submitSchedule(schedule)}
      onCancel={handleCancel}
      isEditMode={isEditMode}
    />,
    <ParticipantTab
      eventId={eventData.id}
      onNext={() => handleNextTab("tab_participants", "tab_advanced_settings")}
      onCancel={handleCancel}
      isEditMode={isEditMode}
      setEventData={setEventData}
    />,
    <AdvancedConfigTab
      eventId={eventData.id}
      onNext={handleFinalizeCreation}
      onCancel={handleCancel}
    />,
  ];

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableEscapeKeyDown
      TransitionComponent={Zoom}
      keepMounted
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          minHeight: "40rem",
          minWidth: "40rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        {isEditMode ? "EDITAR EVENTO" : "NOVO EVENTO"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TabsCustom
          labels={labels.map((tab) => ({
            label: tab.label,
            key: tab.key,
            disabled: !tabValidations[tab.key] && tab.key !== activeTab,
          }))}
          activeTab={activeTab}
          content={labels.map((tab, index) => content[index])}
          onChange={(newTab) => {
            if (tabValidations[newTab] || newTab === activeTab) {
              setActiveTab(newTab);
            }
          }}
          fitContent
          labelFontSize="1rem"
        />
      </DialogContent>

      <Dialog
        open={confirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            borderRadius: "8px",
            maxWidth: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontSize: 18,
          }}
        >
          CONFIRMAÇÃO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "start",
              mb: 2,
              fontSize: "1rem",
            }}
          >
            Deseja realmente cancelar a criação do Evento? Todos os dados
            inseridos serão perdidos.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => setConfirmCancelOpen(false)}
            variant="outline"
            fullWidth
          >
            Não
          </Button>
          <Button onClick={handleConfirmCancel} variant="red" fullWidth>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateEventModal;
