"use client";

import React from "react";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiEducationNext } from "microfrontends/config/api";

interface EducationMyCoursesProps {
  userId: string;
}
const EducationFavoritesNextJS = ({ userId }: EducationMyCoursesProps) => {
  const iframeUrl = `${apiEducationNext}/education-platform/education/journey/favorites/${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "85vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          //minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Favorites - Education Platform"
        allowFullScreen
        allow="autoplay; fullscreen; picture-in-picture"
      />
    </div>
  );
};

export default EducationFavoritesNextJS;
