import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller } from "react-hook-form";
import { TheRainmakersNew } from "utils/utilsIcons";
import { useToast } from "hooks/useToast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import Button from "components/Button";
import Text from "components/Text";
import WhatsappFloatingButton from "components/WhatsappFloatingButton";
import useRegister from "./hooks/useRegister";
import "react-phone-input-2/lib/style.css";
import { apiOrchestrator } from "services/apiService";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import { useUser } from "global/UserContext";
import theme from "styles/theme";
import * as S from "./styles";

export const RegisterIframe: React.FC = () => {
  const { initializeUserContext } = useUser();
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const { control, handleSubmit, errors, onSubmit, loading } = useRegister(
    setDuplicateEmailError,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [inviteId, setInviteId] = useState<string | null>(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteAction, setInviteAction] = useState("accept");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showInviteRequiredModal, setShowInviteRequiredModal] = useState(false);

  const { toastSuccess, toastError } = useToast();

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const invite = searchParams.get("invite_id");
    if (invite) {
      setInviteId(invite);
      setShowInviteModal(true);
    } else {
      setShowInviteRequiredModal(true);
    }
  }, [location.search]);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleInviteResponse = async (action: "accept" | "decline") => {
    setInviteAction(action);
    setShowInviteModal(false);

    if (action === "decline" && inviteId) {
      try {
        await respondToInvite(inviteId, null, "decline");
        toastSuccess("Convite recusado com sucesso.");
      } catch (error) {
        console.error("Erro ao recusar o convite:", error);
        toastError("Erro ao recusar o convite.");
      }
    }
  };

  const handleFormSubmit = async (data: any) => {
    try {
      const response = await onSubmit(data);

      if (!response) {
        return;
      }

      toastSuccess("Cadastro realizado com sucesso!");

      try {
        const loginResponse = await apiOrchestrator.post("/auth/login", {
          email: data.email,
          password: data.password,
        });

        if (loginResponse.data.access_token) {
          const token = loginResponse.data.access_token;
          const user_id = loginResponse.data.user_id;

          await initializeUserContext(user_id, token);

          window.open(
            "https://rainmakers.algoricca.com/dashboard/education/showcase",
            "_blank",
          );

          setRegistrationSuccess(true);
        }
      } catch (error) {
        console.error("Erro no login automático:", error);
        toastError(
          "Erro ao realizar login automático. Por favor, faça login manualmente.",
        );
      }
    } catch (error: any) {
      if (
        error.response?.data?.detail?.includes(
          "duplicate key value violates unique constraint",
        )
      ) {
        setDuplicateEmailError(true);
      } else {
        let errorMessage =
          "Erro ao realizar o cadastro. Verifique os dados e tente novamente.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          errorMessage = error.response.data.detail;
        }
        toastError(errorMessage);
      }
    }
  };

  const respondToInvite = async (
    inviteId: string,
    userId: string | null,
    action: "accept" | "decline",
  ) => {
    const url = `/invites/invite/${inviteId}/respond-invite?action=${action}${userId ? `&user_id=${userId}` : ""}`;
    const response = await apiOrchestrator.post(url);
    return response.data;
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <S.Background>
      <S.Container>
        {registrationSuccess ? (
          <S.SuccessCard>
            <S.Image src={TheRainmakersNew} />
            <Text size="xLarge" className="text-title">
              Cadastro Realizado com Sucesso!
            </Text>
            <S.SuccessMessage>
              <Text size="medium">
                Parabéns! Seu cadastro foi realizado com sucesso.
              </Text>
              <Text size="medium">
                Uma nova guia foi aberta com a plataforma Rainmakers.
              </Text>
              <Text size="medium">
                Caso a guia não tenha sido aberta automaticamente,{" "}
                <S.LinkText
                  onClick={() =>
                    window.open(
                      "https://rainmakers.algoricca.com/dashboard/education/showcase",
                      "_blank",
                    )
                  }
                >
                  clique aqui
                </S.LinkText>
              </Text>
            </S.SuccessMessage>
            <S.ThankYouMessage>
              <Text size="large">
                Obrigado por se juntar à comunidade Rainmakers!
              </Text>
            </S.ThankYouMessage>
          </S.SuccessCard>
        ) : (
          <S.Content>
            <S.Card>
              <S.Image src={TheRainmakersNew} />
            </S.Card>
            <S.Form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="padding-top-24"></div>
              <Text
                size={isMobile ? "medium" : isTablet ? "large" : "xLarge"}
                className="text-title"
              >
                Registre-se!
              </Text>

              <S.ContentForm>
                <S.NameSurnameContainer>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        size={isMobile ? "small" : "medium"}
                      />
                    )}
                  />

                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Sobrenome"
                        variant="outlined"
                        fullWidth
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        size={isMobile ? "small" : "medium"}
                      />
                    )}
                  />
                </S.NameSurnameContainer>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      size={isMobile ? "small" : "medium"}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"br"}
                      preferredCountries={["br", "us", "gb"]}
                      inputStyle={{
                        height: isMobile
                          ? "2.8rem"
                          : isTablet
                            ? "3.2rem"
                            : "4.2rem",
                      }}
                      buttonStyle={{
                        backgroundColor: theme.COLORS.BACKGROUND_QUATERNARY,
                        border: `1px solid ${errors.phone ? theme.COLORS.RED_1 : theme.COLORS.GRAY_40}`,
                      }}
                      dropdownStyle={{
                        backgroundColor: theme.COLORS.BACKGROUND_TERTIARY,
                        color: theme.COLORS.TEXT_PRIMARY,
                      }}
                      placeholder="Digite seu WhatsApp"
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                        style: {
                          backgroundColor: theme.COLORS.BACKGROUND_QUATERNARY,
                        },
                      }}
                      isValid={(value: string) =>
                        !!value.match(/^\+?[1-9]\d{1,14}$/)
                      }
                      specialLabel=""
                      inputClass={`phone-input ${errors.phone ? "error" : ""}`}
                    />
                  )}
                />
                {errors.phone && (
                  <span
                    style={{
                      color: `${theme.COLORS.RED_1}`,
                      fontSize: "10px",
                      marginLeft: "1rem",
                      marginTop: "-0.5rem",
                    }}
                  >
                    {errors.phone.message}
                  </span>
                )}

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Senha"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      size={isMobile ? "small" : "medium"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff
                                  style={{
                                    color: errors.password
                                      ? theme.COLORS.RED_1
                                      : "inherit",
                                  }}
                                />
                              ) : (
                                <Visibility
                                  style={{
                                    color: errors.password
                                      ? theme.COLORS.RED_1
                                      : "inherit",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Confirme a Senha"
                      type={showConfirmPassword ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      size={isMobile ? "small" : "medium"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={toggleShowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff
                                  style={{
                                    color: errors.confirmPassword
                                      ? theme.COLORS.RED_1
                                      : "inherit",
                                  }}
                                />
                              ) : (
                                <Visibility
                                  style={{
                                    color: errors.confirmPassword
                                      ? theme.COLORS.RED_1
                                      : "inherit",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </S.ContentForm>

              <S.ButtonsContainer>
                <Button
                  type="submit"
                  variant="minimal"
                  color="primary"
                  size="medium"
                  onClick={() => navigate("/login")}
                  fullWidth
                >
                  Voltar
                </Button>

                <Button
                  type="submit"
                  variant="primary"
                  color="primary"
                  fullWidth
                  disabled={loading || !inviteId}
                >
                  {loading ? "Carregando..." : "Registrar-se"}
                </Button>
              </S.ButtonsContainer>
            </S.Form>
          </S.Content>
        )}

        <GlobalModal
          open={duplicateEmailError}
          title="ERRO DE CADASTRO"
          description="O e-mail informado já está cadastrado. Tente usar outro e-mail ou recupere sua senha."
          onClose={() => setDuplicateEmailError(false)}
          confirmButtonText="Fechar"
        />

        {/* <GlobalModal
          open={showInviteModal}
          title="CONVITE DE REGISTRO"
          description="Você recebeu um convite para se registrar. Deseja aceitá-lo?"
          onClose={() => setShowInviteModal(false)}
          onConfirm={() => handleInviteResponse("accept")}
          confirmButtonText="Aceitar"
          cancelButtonText="Recusar"
          onCancel={() => handleInviteResponse("decline")}
        /> */}

        <GlobalModal
          open={showInviteRequiredModal}
          title="CONVITE NECESSÁRIO"
          description="Para registrar-se, é necessário um link de convite de um usuário ativo da comunidade."
          onClose={() => navigate("/login")}
          confirmButtonText="Fechar"
        />
      </S.Container>
      <WhatsappFloatingButton />
    </S.Background>
  );
};
