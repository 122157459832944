"use client";

import React from "react";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiDashboardPlatformNext } from "microfrontends/config/api";

interface CommunityProfileProps {
  userId: string;
  pageName: string;
  formId: string;
}
const DashboardsCommunityProfile = ({
  userId,
  pageName,
  formId,
}: CommunityProfileProps) => {
  const iframeUrl = `${apiDashboardPlatformNext}/community-profile/${pageName}/${formId}/${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Access Hub"
      />
    </div>
  );
};

export default DashboardsCommunityProfile;
