import React, { useState } from "react";
import { Paper } from "@mui/material";
import { useIsMobile } from "utils/mediaQueries";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { useToast } from "hooks/useToast";


interface ModalInviteLinkProps {
    handleCloseModal: () => void;
    inviteLink: string
}

const ModalInviteLink: React.FC<ModalInviteLinkProps> = (props) => {
    const isMobile = useIsMobile();
    const { toastSuccess, toastWarning } = useToast();

    const handleClickCopyLink = async () => {
        try {

            // Check clipboard permissions
            const permissionStatus = await navigator.permissions.query({
                name: "clipboard-write" as PermissionName,
            });

            if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
                // Attempt to copy using Clipboard API
                copyTextToClipboard(props.inviteLink);
            } else {
                // Fallback to manual copying if permission is denied
                copyTextManually(props.inviteLink);
            }
        } catch (err) {
            console.error("Error handling click copy link:", err);
            toastWarning("Por favor, copie o link manualmente. Problema ao enviar para área de transferência.")
        }
    };


    const copyTextToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toastSuccess('URL copiada com sucesso!')
            })
            .catch((err) => {
                console.error("Failed to copy the URL using Clipboard API.", err);
                copyTextManually(text); // Fallback if Clipboard API fails
            });
    };

    const copyTextManually = (text: string) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand("copy");
            if (successful) {
                toastSuccess("A URL do convite foi copiada!");
            } else {
                toastWarning("Por favor, copie o link manualmente. Problema ao enviar para área de transferência.")
            }
        } catch (err) {
            console.error("Fallback copy failed: ", err);
            toastWarning("Por favor, copie o link manualmente. Problema ao enviar para área de transferência.")
        } finally {
            document.body.removeChild(textarea);
        }
    };

    return (
        <GlobalModal
            open
            title={"LINK DE INDICAÇÃO"}
            confirmButtonText={"Copiar Link"}
            cancelButtonText="Fechar"
            onConfirm={handleClickCopyLink}
            onClose={props.handleCloseModal}
            onCancel={props.handleCloseModal}
            width={isMobile ? "95%" : "700px"}
        >
            <Paper variant="outlined" sx={{ padding: "8px" }}>{props.inviteLink}</Paper>

        </GlobalModal>
    );
};

export default ModalInviteLink;
