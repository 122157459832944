import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import Text from "components/Text";
import Button from "components/Button";
import TabsCustom from "components/Tabs";
import { useUser } from "global/UserContext";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "hooks/useToast";
import theme from "styles/theme";
import * as S from "../styles";

interface ICreateGroupModal {
  open: boolean;
  onClose: () => void;
  onGroupCreated: (groupId: string) => void;
  onUpdate: () => void;
}

const CreateGroupModal: React.FC<ICreateGroupModal> = ({
  open,
  onClose,
  onGroupCreated,
  onUpdate,
}) => {
  const [activeTab, setActiveTab] = useState("tab_group_data");
  const [tabValidations, setTabValidations] = useState<Record<string, boolean>>(
    {
      tab_group_data: true,
      tab_link_users: false,
    },
  );
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [createdGroup, setCreatedGroup] = useState<any | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [availableUsers, setAvailableUsers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const { user } = useUser();
  const { toastInfo, toastError, toastSuccess } = useToast();

  const resetForm = () => {
    setName("");
    setDescription("");
    setSelectedUsers([]);
    setActiveTab("tab_group_data");
    setSearchTerm("");
    setCreatedGroup(null);
    setConfirmCancelOpen(false);
    setTabValidations({
      tab_group_data: true,
      tab_link_users: false,
    });
  };

  useEffect(() => {
    if (activeTab === "tab_link_users" && availableUsers.length === 0) {
      const fetchAvailableUsers = async () => {
        try {
          const response = await apiOrchestrator.get(
            "/user_to_organization/members",
          );
          setAvailableUsers(response.data);
        } catch (error) {
          console.error("Erro ao buscar usuários:", error);
          toastError("Erro ao buscar usuários.");
        }
      };
      fetchAvailableUsers();
    }
  }, [activeTab, toastError]);

  const handleCreateGroup = async () => {
    if (name && description) {
      try {
        const response = await apiOrchestrator.post("/team/", {
          organization_uuid: user?.user_to_organization[0]?.organization?.uuid,
          name,
          description,
        });
        setCreatedGroup(response.data);
        setTabValidations((prev) => ({ ...prev, tab_link_users: true }));
        setActiveTab("tab_link_users");
        onGroupCreated(response.data.uuid);
        toastSuccess("Grupo criado com sucesso!");
        onUpdate();
      } catch (error: any) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode >= 400 && statusCode < 500) {
            toastError(
              "Erro na solicitação. Verifique os dados e tente novamente.",
            );
          } else if (statusCode >= 500) {
            toastError("Erro no servidor. Tente novamente mais tarde.");
          }
        } else {
          toastError("Erro ao criar grupo.");
        }
        console.error("Erro ao criar grupo:", error);
      }
    }
  };

  /**
   * CHANGE USER POSITION ONCE BACKEND AND PROTOTYPE FINAL
   */
  const handleLinkUsers = async () => {
    try {
      const userParams = selectedUsers.map((userId) => {
        const user = availableUsers.find((userItem) => userItem.id === userId);
        return {
          user_uuid: userId,
          team_position: /* user?.group_role?.name || */ "Membro",
        };
      });

      await apiOrchestrator.post(
        `/user_to_team/${createdGroup.uuid}`,
        userParams,
      );
      toastSuccess("Usuários vinculados ao grupo com sucesso!");
      resetForm();
      onClose();
      onUpdate();
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode >= 400 && statusCode < 500) {
          toastError("Erro ao vincular usuários. Verifique os dados.");
        } else if (statusCode >= 500) {
          toastError(
            "Erro no servidor ao vincular usuários. Tente novamente mais tarde.",
          );
        }
      } else {
        toastError("Erro ao vincular usuários ao grupo.");
      }
      console.error("Erro ao vincular usuários:", error);
    }
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId],
    );
  };

  const handleCancel = () => {
    if (createdGroup) {
      setConfirmCancelOpen(true);
    } else {
      resetForm();
      onClose();
      onUpdate();
    }
  };

  const handleConfirmCancel = async () => {
    setConfirmCancelOpen(false);
    if (createdGroup) {
      try {
        await apiOrchestrator.delete(`/team/${createdGroup.uuid}`);
        setCreatedGroup(null);
        toastInfo("Criação do grupo foi cancelada.");
      } catch (error) {
        console.error("Erro ao cancelar o grupo:", error);
        toastError("Erro ao cancelar o grupo.");
      }
    }
    onClose();
    resetForm();
  };

  const filteredUsers = availableUsers.filter((userItem) => {
    const fullName = `${userItem.user?.first_name || ""} ${userItem.user?.last_name || ""}`;
    const roleName = userItem.group_role?.name || "";

    return (
      fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      roleName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const tabs = [
    {
      key: "tab_group_data",
      label: "Dados do Grupo",
      content: (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Nome do Grupo Temático"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ input: { color: theme.COLORS.TEXT_PRIMARY } }}
          />
          <TextField
            label="Descrição"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ textarea: { color: theme.COLORS.TEXT_PRIMARY } }}
          />
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              gap: "3rem",
            }}
          >
            <Button
              variant="outline"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              color="primary"
              onClick={handleCreateGroup}
              disabled={!name || !description}
              fullWidth
            >
              Vincular Usuários
            </Button>
          </DialogActions>
        </Box>
      ),
    },
    {
      key: "tab_link_users",
      label: "Vincular Usuários",
      content: (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text size="medium">
              Usuários vinculados ({filteredUsers.length})
            </Text>
            <TextField
              placeholder="Buscar por Usuário"
              margin="normal"
              value={searchTerm}
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={S.SearchService}
            />
          </Box>
          <Box
            sx={{
              maxHeight: 220,
              overflowY: "auto",
              borderRadius: 1,
              backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
            }}
          >
            {filteredUsers.length > 0 ? (
              <List>
                {filteredUsers.map((userItem) => (
                  <ListItem
                    key={userItem.uuid}
                    sx={{
                      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
                      marginBottom: "0.5rem",
                      borderRadius: "8px",
                    }}
                  >
                    <Checkbox
                      edge="start"
                      onChange={() => toggleUserSelection(userItem.user.id)}
                      checked={selectedUsers.includes(userItem.user.id)}
                      sx={{
                        color: theme.COLORS.PRIMARY,
                        "&.Mui-checked": {
                          color: theme.COLORS.PRIMARY,
                        },
                      }}
                    />
                    <ListItemText
                      primary={`${userItem.user?.first_name || ""} ${userItem.user?.last_name || ""}`}
                      secondary={userItem.group_role?.name || ""}
                      sx={{ color: theme.COLORS.TEXT_SECONDARY }}
                    />
                    <ListItemSecondaryAction></ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Text color="gray">Nenhum usuário encontrado.</Text>
            )}
          </Box>

          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Button
              variant="outline"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              color="primary"
              onClick={handleLinkUsers}
              disabled={selectedUsers.length === 0}
              fullWidth
            >
              Criar Grupo Temático
            </Button>
          </DialogActions>
        </Box>
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        NOVO GRUPO TEMÁTICO
      </DialogTitle>

      <Divider />

      <DialogContent>
        <TabsCustom
          labels={tabs.map((tab) => ({
            label: tab.label,
            key: tab.key,
            disabled: !tabValidations[tab.key] && tab.key !== activeTab,
          }))}
          activeTab={activeTab}
          content={tabs.map((tab) => tab.content)}
          onChange={(newTab) => {
            if (tabValidations[newTab] || newTab === activeTab) {
              setActiveTab(newTab);
            }
          }}
          fitContent
        />
      </DialogContent>

      <Dialog
        open={confirmCancelOpen}
        onClose={() => setConfirmCancelOpen(false)}
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            borderRadius: "8px",
            maxWidth: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontSize: 18,
          }}
        >
          CONFIRMAÇÃO
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "start",
              mb: 2,
              fontSize: "1rem",
            }}
          >
            Deseja realmente cancelar a criação do Grupo Temático? Todos os
            dados inseridos serão perdidos.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => setConfirmCancelOpen(false)}
            variant="outline"
            fullWidth
          >
            Não
          </Button>
          <Button onClick={handleConfirmCancel} variant="primary" fullWidth>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateGroupModal;
