import React from "react";
import { Tooltip } from "@mui/material";
import { ContainerText } from "./styles";
import useHasTextOverflow from "./hooks/useHasTextOverflow";
import theme from "styles/theme";

export enum TextSize {
  xSmall = "xSmall",
  small = "small",
  medium = "medium",
  large = "large",
  xLarge = "xLarge",
}

interface ITextProps {
  children: React.ReactNode;
  size?:
    | TextSize
    | "XXSmall"
    | "xSmall"
    | "small"
    | "medium"
    | "large"
    | "xLarge";
  bold?: boolean;
  color?: "white" | "black" | "gray" | "green" | "red" | "yellow" | "primary";
  secondary?: boolean;
  className?: string;
  truncate?: boolean;
  isTooltip?: boolean;
  tooltip?: string;
  fontWeight?: number;
  textAlign?: "left" | "center" | "right" | "justify";
  flex?: number;
  underline?: boolean;
  maxWidth?: string | number;
  onClick?: () => void;
}

const Text = ({
  children,
  size = TextSize.medium,
  bold = false,
  color = "white",
  secondary = false,
  className,
  truncate = false,
  isTooltip = false,
  tooltip,
  fontWeight,
  textAlign,
  flex,
  underline = false,
  maxWidth = "100%",
  onClick,
}: ITextProps) => {
  const { ref, hasOverflow } = useHasTextOverflow(children as string);

  const content = (
    <ContainerText
      ref={ref}
      size={size}
      bold={bold}
      color={color}
      secondary={secondary}
      className={className}
      truncate={truncate}
      fontWeight={fontWeight}
      textAlign={textAlign}
      flex={flex}
      underline={underline}
      onClick={onClick}
      maxWidth={maxWidth}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      {children}
    </ContainerText>
  );

  return (truncate && hasOverflow) || isTooltip ? (
    <Tooltip
      title={tooltip || (children as string)}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
            color: theme.COLORS.TEXT_PRIMARY,
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "none",
            fontSize: theme.FONT_SIZE.SM,
            maxWidth: "20rem",
          },
        },
        arrow: {
          sx: {
            color: theme.COLORS.BACKGROUND_PRIMARY,
          },
        },
      }}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default Text;
