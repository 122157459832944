import React, { useState } from "react";
import {
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import Spin from "components/Spin";
import Text from "components/Text";
import Button from "components/Button";
import CalendarFilter from "./components/CalendarFilter";
import EventCarousel from "./components/EventCarousel";
import EventInfo from "./components/EventInfo";
import { useEvents } from "./hooks/useEvents";
import { useNextEvents } from "./hooks/useNextEvents";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import theme from "styles/theme";

dayjs.locale("pt-br");

const AgendaPage: React.FC = () => {
  const {
    rawEvents,
    events,
    eventsToday,
    eventsFirstSlot,
    refreshEvents,
    loading,
    error,
  } = useEvents();
  const {
    nextEvents,
    loading: loadingNextEvents,
    error: errorNextEvents,
    refreshEvents: refreshNextEvents,
  } = useNextEvents();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [tab, setTab] = useState<"MEMBER" | "COMMUNITY">("COMMUNITY");

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const eventDates = events.map((event) =>
    dayjs(event.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
  );

  const filteredEvents = events.filter((event) =>
    dayjs(event.date, "DD/MM/YYYY").isSame(selectedDate, "day"),
  );

  const selectedDateFormatted = selectedDate
    ? dayjs(selectedDate).format("DD [de] MMMM [de] YYYY")
    : "";

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", padding: 0 }}>
        <Spin />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Container sx={{ display: "flex", justifyContent: "center", padding: 4 }}>
        <Text color="gray">
          Erro ao carregar eventos. Tente novamente mais tarde.
        </Text>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        marginBottom: "5rem",
        padding: isMobile ? 0.5 : "auto",
        background:
          isMobile || isTablet
            ? theme.COLORS.BACKGROUND_SECONDARY
            : theme.COLORS.BACKGROUND_SECONDARY,

        borderRadius: isMobile || isTablet ? 2 : 0,
      }}
    >
      <Box
        display="flex"
        maxWidth={isMobile || isTablet ? "100%" : "25%"}
        gap="0"
        mb="1rem"
        padding={isMobile || isTablet ? 3 : 0}
      >
        <Button
          size="small"
          variant={tab === "MEMBER" ? "primary" : "outline"}
          onClick={() => setTab("MEMBER")}
          fullWidth
          style={{ borderRadius: "8px 0px 0px 8px" }}
          disabled
        >
          Membro
        </Button>
        <Button
          size="small"
          variant={tab === "COMMUNITY" ? "primary" : "outline"}
          onClick={() => setTab("COMMUNITY")}
          fullWidth
          style={{ borderRadius: "0px 8px 8px 0px" }}
        >
          Comunidade
        </Button>
      </Box>
      <Box>
        {nextEvents.length > 0 ? (
          <>
            <Box sx={{ paddingBottom: isMobile ? 1 : 0 }}>
              <Text size="medium" bold>
                Próximos Eventos
              </Text>
            </Box>
            <EventCarousel
              events={rawEvents}
              nextEvents={nextEvents}
              refreshEvents={refreshEvents}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: isMobile ? "2rem" : "3rem",
            }}
          >
            <Text size="medium" color="gray">
              Nenhum evento encontrado.
            </Text>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        sx={{
          background: theme.COLORS.BACKGROUND_PRIMARY,
          borderRadius: 2,
          padding: isMobile || isTablet ? -2 : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: isMobile || isTablet ? 0 : 2.5,
            flex: isMobile ? 1 : 0.5,
          }}
        >
          <CalendarFilter
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
            eventDates={eventDates}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            paddingTop: isMobile ? "2rem" : "2rem",
            paddingRight: isMobile || isTablet ? 0 : 0.5,
            flex: isMobile ? 1 : 0.5,
          }}
        >
          <Box
            sx={{ marginBottom: 0.5, padding: isMobile || isTablet ? 1 : 0 }}
          >
            <Text color="primary" bold>
              <Text size="medium" bold color="primary">
                {selectedDateFormatted}
              </Text>
            </Text>
          </Box>
          <Box
            sx={{
              padding: isMobile || isTablet ? 1 : 0,
            }}
          >
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event) => (
                <Accordion
                  key={event.id}
                  sx={{
                    color: theme.COLORS.WHITE_1,
                    marginBottom: 0.75,
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon sx={{ color: theme.COLORS.PRIMARY }} />
                    }
                    sx={{
                      background: theme.COLORS.BACKGROUND_SECONDARY,
                      borderRadius: 2,
                      //padding: isMobile ? "0.5rem" : "1rem",
                    }}
                  >
                    <Text size="small" bold>
                      {event.title}
                    </Text>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      background: theme.COLORS.BACKGROUND_SECONDARY,
                      //padding: isMobile ? "0.5rem" : "1rem",
                    }}
                  >
                    <Text size="small">{event.location}</Text>
                    {event.participants.participants.some(
                      (participant) =>
                        participant.participant_role.code === "COORDINATOR",
                    ) && (
                      <Text size="small">
                        Coordenador:{" "}
                        {
                          event.participants.participants.find(
                            (participant) =>
                              participant.participant_role.code ===
                              "COORDINATOR",
                          )?.first_name
                        }{" "}
                        {
                          event.participants.participants.find(
                            (participant) =>
                              participant.participant_role.code ===
                              "COORDINATOR",
                          )?.last_name
                        }
                      </Text>
                    )}

                    {event.participants.participants.some(
                      (participant) =>
                        participant.participant_role.code === "PRESENTER",
                    ) && (
                      <Text size="small">
                        Palestrantes:{" "}
                        {event.participants.participants
                          .filter(
                            (participant) =>
                              participant.participant_role.code === "PRESENTER",
                          )
                          .map(
                            (presenter) =>
                              `${presenter.first_name} ${presenter.last_name}`,
                          )
                          .join(", ")}
                      </Text>
                    )}

                    <EventInfo
                      totalParticipants={event.participants.total}
                      duration={event.duration}
                      time={event.time}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: isMobile ? "2rem" : "3rem",
                }}
              >
                <Text size="medium" color="gray">
                  Nenhum evento encontrado para esta data.
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AgendaPage;
