import Spin from "components/Spin";
import Button from "components/Button";
import { JaaSMeeting } from "@jitsi/react-sdk"

import { MeetingRoom as IMeetingRoom } from "pages/MeetingRoom/types/meetingTypes";
import * as S from "./styles"
import { useEffect, useState } from "react";
import { useUser } from "global/UserContext";
import { apiMeeting } from "services/apiService";
import { useToast } from "hooks/useToast";


interface JitsiEmbedProps {
    meetingRoom: IMeetingRoom;
    videoLoading: boolean
    onLoad: () => void;
}

interface IJitsiAuth {
    app_id: string
    token: string
}
export const JitsiVideoEmbed = ({ meetingRoom, videoLoading, onLoad }: JitsiEmbedProps) => {
    const { toastError } = useToast();
    const [jitsiAuth, setJitsiAuth] = useState<IJitsiAuth>()
    const [error, setError] = useState<boolean>(false)
    const { user } = useUser()

    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        getToken()
    }, [])

    const getToken = async () => {
        try {
            const response = await apiMeeting.get(`/v1/integrations/jitsi/token?user_id=${user?.id}`)
            setJitsiAuth(response?.data ?? '')
            onLoad()
        } catch (error) {
            setError(true)
            toastError('Algo deu errado ao carregar o vídeo')
            console.log('VIDEOCONF', { error })
        }
    }


    const renderJitsi = () => {

        if (videoLoading && !error || !jitsiAuth) {
            return <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin tip="Loading video..." />
            </div>
        } else if (error || (!videoLoading && !jitsiAuth)) {
            return <div style={{ display: "flex", justifyContent: "center" }}>
                <h1>Erro ao carregar o vídeo</h1>
            </div>
        }
        return < JaaSMeeting
            appId={jitsiAuth?.app_id}
            roomName={meetingRoom.room_data.title}
            jwt={jitsiAuth?.token}
            configOverwrite={{
                disableLocalVideoFlip: true,
                backgroundAlpha: 0,
                defaultLanguage: 'pt-BR',
                toolbarConfig: {
                    timeout: 2000
                }
            }}
            interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4
            }}
            userInfo={{
                displayName: `${user?.first_name} ${user?.last_name}`,
                email: user?.email ?? ""
            }}
            spinner={Spin}
            onApiReady={(externalApi) => { console.log({ externalApi }); onLoad(); }}
        />
    }


    const roomIndex = Number(meetingRoom.room_data.position) % 7

    return (
        <>
            <Button
                type="submit"
                title={`Entrar na ${meetingRoom.room_data.title}`}
                size="small"
                variant="primary"
                onClick={() => setModalOpen(true)}
                style={{
                    width: "108px",
                    marginLeft: `${142.2 * roomIndex}px`,
                    fontWeight: "bold",
                    fontSize: "10px",
                }}
                disabled={!meetingRoom.participation_status.is_participant}
            >
                Entrar na reunião
            </Button>
            <S.VideoContainer >

                {modalOpen && renderJitsi()}
            </S.VideoContainer >
        </>
    )

}
