import styled from "styled-components";
import theme from "styles/theme";
import "styles/global";

export const Wrapper = styled.main<{ backgroundImage: string }>`
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${(props) => props.backgroundImage});
  background-size: 99% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 85vh;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: ${theme.COLORS.GRAY_10};
  overflow: hidden;

  @media screen and (max-width: 768px) {
    overflow-x: auto;
    min-width: 600px;
    margin-right: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`;

export const ImgContent = styled.div`
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 2;

  img {
    max-width: 350px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 12px;
`;
