import React, { useEffect, useState } from "react";
import Courses from "microfrontends/Education/AdminEducation/Courses";
import Lessons from "microfrontends/Education/AdminEducation/Lessons";
import TabsCustom from "components/Tabs";
import { useTab } from "global/TabContext";
import { useUser } from "global/UserContext";
import * as S from "./styles";
import Categories from "microfrontends/Education/AdminEducation/Categories";

const labels = [
  {
    label: "Categorias",
    sub_label: "",
    key: "tab_orchestrator_education_category",
    tab_name: "education-category",
  },
  {
    label: "Cursos",
    sub_label: "",
    key: "tab_orchestrator_education_curses",
    tab_name: "education-courses",
  },
  {
    label: "Aulas",
    sub_label: "",
    key: "tab_orchestrator_education_lessons",
    tab_name: "education-lessons",
  },
];

export const OrchestratorEducation = () => {
  const [activeTab, setActiveTab] = useState(labels[0].key);
  const { setActiveTabContext, activeTabContext } = useTab();
  const { user } = useUser();
  const userId = user?.id;

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  const changeTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={[
            userId && <Categories userId={userId} />,
            <Courses />,
            userId && <Lessons userId={userId} />,
          ]}
          onChange={(key) => changeTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
