import styled from "styled-components";
import { getBackgroundImage } from "utils/backgroundDashboardsImages";

interface ContentProps {
  pageName: string;
}

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: #e9e9f0;
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }

  /*   &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ pageName }) =>
    pageName ? `url(${getBackgroundImage(pageName)})` : "none"};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    transition: background-image 0.3s ease-in-out;
    z-index: 0;
  } */
`;
