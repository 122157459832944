import styled from "styled-components";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";

export const Background = styled.div`
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .line {
    border-bottom: 1px solid ${theme.COLORS.GRAY_10};
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    flex-direction: row;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 1rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 0;
    gap: 0;
    margin: 0;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    flex-direction: column;
    max-width: 900px;
    justify-content: center;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    margin-bottom: 1rem;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    height: 25vh;
    margin-bottom: 0;

    &::before {
      content: "";
      background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.5);
      z-index: -1;
    }
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    width: 100%;
    max-width: 350px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 450px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 200px;

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    max-width: 140px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    max-width: 180px;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 200px;
  }
`;

export const Form = styled.form`
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  border-radius: 16px;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 1rem 0;
  position: relative;

  box-shadow:
    0px 8px 15px rgba(0, 0, 0, 0.3),
    0px -24px 12px rgba(0, 0, 0, 0.25);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  .text-title {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.408px;
    padding: 0 2rem;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    border-radius: 24px 24px 0 0;
    margin: 0;
    height: 75vh;
    align-items: space-evenly;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) and (max-width: ${BREAKPOINTS
      .WIDTH.LG}) {
    max-width: 400px;
    padding: 2rem;
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.LG}) {
    max-width: 441px;
    padding: 2.5rem;
    height: max-content;
  }

  .MuiFormHelperText-root {
    color: ${theme.COLORS.RED_1};
  }
`;

export const ContentForm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  .text-form {
    margin-bottom: -0.5rem;
  }

  .error-message {
    display: flex;
    justify-content: start;
    margin-top: 0.5rem;
  }

  .phone-input {
    width: 100%;
    height: 4.2rem;
    background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
    color: ${theme.COLORS.TEXT_PRIMARY};
    border: 1px solid ${theme.COLORS.GRAY_40};
    border-radius: 4px;
    padding-left: 50px;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.5px;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: ${theme.COLORS.GRAY_10};
    }

    &:focus {
      border-color: ${theme.COLORS.GRAY_10};
      outline: none;
      //box-shadow: 0 0 0 2px ${theme.COLORS.GRAY_10}33;
    }
  }

  /*  .react-tel-input .flag-dropdown {
    background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
    border: 1px solid ${theme.COLORS.GRAY_40};
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  .react-tel-input .flag-dropdown:hover {
    border-color: ${theme.COLORS.GRAY_10};
  }

  .react-tel-input .flag-dropdown:focus {
    border-color: ${theme.COLORS.PRIMARY};
    outline: none;
    box-shadow: 0 0 0 2px ${theme.COLORS.PRIMARY}33;
  } */
`;

export const NameSurnameContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  > div {
    flex: 1;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    flex-direction: column;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 6rem;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.COLORS.GRAY_10};
  margin-bottom: 1rem;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  .invite-modal-buttons {
    display: flex;
    width: 100%;
    gap: 1rem;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    padding: 1rem;
    gap: 1.5rem;

    .invite-modal-buttons {
      flex-direction: column;
    }
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  span {
    padding: 0 10px;
    color: ${theme.COLORS.TEXT_PRIMARY};
  }

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 2px solid ${theme.COLORS.GRAY_10};
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
`;
