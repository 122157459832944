import React from 'react';
import Text from 'components/Text';
import { TheRainmakersNew } from 'utils/utilsIcons';
import * as S from './styles';

interface RegistrationSuccessProps {
  onRedirect?: () => void;
}

export const RegistrationSuccess: React.FC<RegistrationSuccessProps> = ({ onRedirect }) => {
  return (
    <S.Container>
      <S.SuccessCard>
        <S.Image src={TheRainmakersNew} alt="Rainmakers Logo" />
        <Text size="xLarge" className="text-title">
          Bem-vindo à Rainmakers!
        </Text>
        <S.SuccessMessage>
          <Text size="medium">
            Seu cadastro foi realizado com sucesso e você já tem acesso à plataforma.
          </Text>
          <Text size="medium">
            Uma nova guia foi aberta com o conteúdo da Rainmakers.
          </Text>
          <Text size="medium">
            Caso a guia não tenha sido aberta automaticamente,{' '}
            <S.LinkText onClick={onRedirect}>
              clique aqui
            </S.LinkText>
          </Text>
        </S.SuccessMessage>
        <S.ThankYouMessage>
          <Text size="large">
            Obrigado por se juntar à comunidade Rainmakers!
          </Text>
        </S.ThankYouMessage>
      </S.SuccessCard>
    </S.Container>
  );
}; 