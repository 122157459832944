import styled, { keyframes } from "styled-components";
import theme from "styles/theme";

interface propsContainer {
  open: boolean;
}

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_TERTIARY};
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  transition: padding-left 0.5s;
  overflow: hidden;
  max-width: 1366px;
  position: relative;
  border-right: 1px solid ${theme.COLORS.BACKGROUND_QUATERNARY};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NewsContainer = styled.div`
  display: flex;
  margin-left: 9rem;
`;

export const AnimatedWrapper = styled.div<{ isVisible: boolean }>`
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.3s
    forwards;
  position: relative;
  width: ${({ isVisible }) => (isVisible ? "25rem" : "0")};
  overflow: hidden;
  margin-left: 9.1rem;
`;

export const UserCardContainer = styled.div`
  width: 20rem;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-left: 9rem;
  height: 100%;
`;

export const Wrapper = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-left: ${({ open }) => (open ? "18rem" : "0px")};
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
`;

export const WrapperMobile = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-top: 64px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
  padding: 1rem 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  padding-left: 1rem;
`;

export const ContentMobile = styled.div<{ isCommunity: boolean }>`
  display: flex;
  width: 100%;
  height: ${({ isCommunity }) => (isCommunity ? "100%" : "max-content")};
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  padding: 0.5rem;
  padding-bottom: 2rem;
`;

export const IconButtonContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
  margin-top: 5rem;
  margin-left: 8.5rem;
`;
