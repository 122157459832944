import styled from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 3rem;
`;
