import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiOrchestrator } from "services/apiService";
import Button from "components/Button";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import Text from "components/Text";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import theme from "styles/theme";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import * as S from "./styles";

const confirmForgotPasswordSchema = yup.object().shape({
  confirmationCode: yup
    .string()
    .matches(/^[0-9]{6}$/, "O código deve conter exatamente 6 dígitos")
    .required("Código de confirmação é obrigatório"),
  newPassword: yup
    .string()
    .required("Nova senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 8 caracteres"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "As senhas devem coincidir")
    .required("Confirmação de senha é obrigatória"),
});

const passwordRequirements = [
  { test: /[A-Z]/, message: "Contém pelo menos 1 letra maiúscula" },
  { test: /[a-z]/, message: "Contém pelo menos 1 letra minúscula" },
  { test: /\d/, message: "Contém pelo menos 1 número" },
  { test: /[\W_]/, message: "Contém pelo menos 1 caractere especial" },
  { test: /.{8,}/, message: "Contém no mínimo 8 caracteres" },
];

interface ConfirmForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
  email: string;
}

const ConfirmForgotPasswordModal: React.FC<ConfirmForgotPasswordModalProps> = ({
  open,
  onClose,
  email,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(confirmForgotPasswordSchema),
  });
  const { toastSuccess, toastError } = useToast();
  const { setToken, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const confirmPassword = watch("confirmPassword");

  const onSubmit = async (data: {
    confirmationCode: string;
    newPassword: string;
  }) => {
    setLoading(true);
    try {
      const response = await apiOrchestrator.post(
        "/auth/confirm_forgot_password",
        {
          email,
          password: data.newPassword,
          confirmation_code: data.confirmationCode,
        },
      );

      if (response.data.status === "success") {
        const { access_token, user_id } = response.data;
        setToken(access_token);

        const userResponse = await apiOrchestrator.get(`/auth/me/${user_id}`);
        setUser(userResponse.data);

        toastSuccess("Senha redefinida com sucesso!");
        onClose();
        navigate("/dashboard/cockpit");
      } else {
        toastError(
          "Erro ao redefinir a senha. Verifique o código e tente novamente.",
        );
      }
    } catch (error: any) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApiError = (error: any) => {
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.detail || "Erro inesperado.";

      switch (statusCode) {
        case 400:
          toastError("Dados inválidos. Verifique o código e a senha.");
          break;

        case 429:
          toastError(
            "Muitas tentativas falhas. Aguarde um tempo e tente novamente.",
          );
          break;

        case 500:
          if (errorMessage.includes("LimitExceededException")) {
            toastError(
              "Limite de tentativas excedido. Tente novamente mais tarde.",
            );
          } else if (errorMessage.includes("Confirmation code has expired")) {
            toastError(
              "O código de confirmação expirou. Solicite um novo código.",
            );
          } else {
            toastError(
              "Erro no servidor. Por favor, tente novamente mais tarde.",
            );
          }
          break;

        default:
          toastError(`Erro: ${errorMessage}`);
      }
    } else if (error.request) {
      toastError("Falha de conexão. Verifique sua internet.");
    } else {
      toastError("Erro inesperado. Por favor, tente novamente.");
    }
  };

  const checkPasswordRequirements = (password: string) => {
    return passwordRequirements.map((req) => ({
      ...req,
      isValid: req.test.test(password),
    }));
  };

  const isFormValid = () => {
    const passwordReqValid = checkPasswordRequirements(newPassword).every(
      (req) => req.isValid,
    );
    const passwordsMatch = confirmPassword === newPassword;

    return (
      passwordReqValid && passwordsMatch && Object.keys(errors).length === 0
    );
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <GlobalModal
      title="CONFIRMAÇÃO"
      open={open}
      onClose={onClose}
      width="400px"
    >
      <S.Content>
        <Text size="medium">
          Entre com o código recebido em seu e-mail e com uma nova senha.
        </Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="confirmationCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Código de Confirmação"
                fullWidth
                error={!!errors.confirmationCode}
                helperText={errors.confirmationCode?.message}
                style={{ marginBottom: "1rem" }}
                inputProps={{
                  maxLength: 6,
                  inputMode: "numeric",
                }}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/\D/g, "");
                }}
                size={isMobile ? "small" : "medium"}
              />
            )}
          />

          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nova Senha"
                type={showNewPassword ? "text" : "password"}
                fullWidth
                error={!!errors.newPassword}
                //helperText={errors.newPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: "1rem" }}
                size={isMobile ? "small" : "medium"}
                onChange={(e) => {
                  field.onChange(e);
                  setNewPassword(e.target.value);
                }}
              />
            )}
          />

          <Box sx={{ textAlign: "left", marginBottom: "1rem" }}>
            <Text size="small">Requisitos de senha:</Text>
            {checkPasswordRequirements(newPassword).map((req, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                {req.isValid ? (
                  <CheckCircle
                    sx={{ fontSize: 12, color: theme.COLORS.SUCCESS }}
                  />
                ) : (
                  <Cancel sx={{ fontSize: 12, color: theme.COLORS.ERROR }} />
                )}
                <Text size="small">{req.message}</Text>
              </Box>
            ))}
          </Box>

          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirme a Nova Senha"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                error={!!errors.confirmPassword}
                //helperText={errors.confirmPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: "1rem" }}
                size={isMobile ? "small" : "medium"}
              />
            )}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            {confirmPassword === newPassword ? (
              <CheckCircle sx={{ fontSize: 12, color: theme.COLORS.SUCCESS }} />
            ) : (
              <Cancel sx={{ fontSize: 12, color: theme.COLORS.ERROR }} />
            )}
            <Text size="small">
              {confirmPassword === newPassword
                ? "As senhas coincidem"
                : "As senhas não coincidem"}
            </Text>
          </Box>

          <Button
            type="submit"
            variant="primary"
            fullWidth
            disabled={loading || !isFormValid()}
            style={{
              backgroundColor: isFormValid()
                ? theme.COLORS.PRIMARY
                : theme.COLORS.GRAY_60,
              color: isFormValid() ? theme.COLORS.BLACK : theme.COLORS.GRAY_40,
              marginTop: "1rem",
              padding: "0.75rem 0",
              fontSize: "1rem",
            }}
          >
            {loading ? (
              <CircularProgress size={16} sx={{ color: theme.COLORS.BLACK }} />
            ) : (
              "Confirmar e Redefinir Senha"
            )}
          </Button>
        </form>
      </S.Content>
    </GlobalModal>
  );
};

export default ConfirmForgotPasswordModal;
