import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useToast } from "hooks/useToast";
import Button from "components/Button";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import theme from "styles/theme";

dayjs.locale("pt-br");
dayjs.extend(utc);
dayjs.extend(timezone);
const BRAZIL_TIMEZONE = "America/Sao_Paulo";

const daysOfWeek = ["D", "S", "T", "Q", "Q", "S", "S"];
const daysOfWeekMap: { [key: string]: string } = {
  Sunday: "domingo",
  Monday: "segunda-feira",
  Tuesday: "terça-feira",
  Wednesday: "quarta-feira",
  Thursday: "quinta-feira",
  Friday: "sexta-feira",
  Saturday: "sábado",
};

const calculateOrdinal = (date: Dayjs): string => {
  const dayOfMonth = date.date();
  const weekOfMonth = Math.ceil(dayOfMonth / 7);
  const ordinals = ["primeira", "segunda", "terceira", "quarta", "quinta"];
  return ordinals[weekOfMonth - 1];
};

const generateDurationOptions = () => {
  const options = [];
  for (let i = 15; i <= 1440; i += 15) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    let label = "";

    if (hours > 0) {
      label += `${hours} hora${hours > 1 ? "s" : ""}`;
    }
    if (minutes > 0) {
      label += `${hours > 0 ? " e " : ""}${minutes} minuto${minutes > 1 ? "s" : ""}`;
    }

    options.push({ value: i, label });
  }
  return options;
};

interface ScheduleTabProps {
  eventId: string;
  scheduleData?: any;
  onNext: (schedule: any) => void;
  onCancel: () => void;
  isEditMode?: boolean;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({
  eventId,
  scheduleData,
  onNext,
  onCancel,
  isEditMode = false,
}) => {
  const now = dayjs().tz(BRAZIL_TIMEZONE);
  const { toastSuccess, toastError, toastInfo } = useToast();
  const [startDate, setStartDate] = useState<Dayjs | null>(
    scheduleData?.rule?.init_date ? dayjs(scheduleData.rule.init_date) : null,
  );
  const [startTime, setStartTime] = useState<Dayjs | null>(
    scheduleData?.rule?.init_hour
      ? dayjs.utc(scheduleData.rule.init_hour, "HH:mm:ss").tz(BRAZIL_TIMEZONE)
      : null,
  );
  const [duration, setDuration] = useState<number>(
    scheduleData?.rule?.duration || 30,
  );
  const [isRecurrent, setIsRecurrent] = useState<boolean>(
    scheduleData?.rule?.is_recurrent || false,
  );
  const [recurrenceFrequency, setRecurrenceFrequency] = useState<string>(
    scheduleData?.rule?.recurrence_frequency || "DAILY",
  );
  const [recurrenceInterval, setRecurrenceInterval] = useState<number>(
    scheduleData?.rule?.recurrence_rule_limit || 1,
  );
  const [recurrenceEnd, setRecurrenceEnd] = useState<string>(
    scheduleData?.rule?.recurrence_end_rule === "EXACT_DATE"
      ? "DATE"
      : scheduleData?.rule?.recurrence_end_rule === "AFTER_N_OCCURRENCES"
        ? "COUNT"
        : "NONE",
  );
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Dayjs | null>(
    scheduleData?.rule?.recurrence_end_date
      ? dayjs(scheduleData.rule.recurrence_end_date)
      : null,
  );
  const [recurrenceCount, setRecurrenceCount] = useState<number>(
    scheduleData?.rule?.recurrence_count || 1,
  );
  const [selectedWeekDays, setSelectedWeekDays] = useState<number[]>(
    scheduleData?.rule?.recurrence_by_week
      ? scheduleData.rule.recurrence_by_week.map((day: string) =>
          ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].indexOf(day),
        )
      : [],
  );

  const [monthlyOption, setMonthlyOption] = useState<string>(
    scheduleData?.recurrence_by_month === "DAY_RULE" ? "DAY" : "WEEK",
  );
  const [monthlyDay, setMonthlyDay] = useState<number | null>(null);
  const [monthlyWeek, setMonthlyWeek] = useState<string>("first");
  const [monthlyWeekDay, setMonthlyWeekDay] = useState<string>("monday");

  useEffect(() => {
    if (scheduleData) {
      setStartDate(
        scheduleData.init_date ? dayjs(scheduleData.init_date) : null,
      );
      setStartTime(
        scheduleData.init_hour
          ? dayjs.utc(scheduleData.init_hour, "HH:mm:ss").tz(BRAZIL_TIMEZONE)
          : null,
      );
      setDuration(scheduleData.duration || 30);
      setIsRecurrent(scheduleData.is_recurrent || false);
      setRecurrenceFrequency(scheduleData.recurrence_frequency || "DAILY");
      setRecurrenceInterval(scheduleData.recurrence_rule_limit || 1);
      setRecurrenceEnd(
        scheduleData.recurrence_end_rule === "EXACT_DATE"
          ? "DATE"
          : scheduleData.recurrence_end_rule === "AFTER_N_OCCURRENCES"
            ? "COUNT"
            : "NONE",
      );
      setRecurrenceEndDate(
        scheduleData.recurrence_end_date
          ? dayjs(scheduleData.recurrence_end_date)
          : null,
      );
      setRecurrenceCount(scheduleData.recurrence_count || 1);
      setSelectedWeekDays(
        scheduleData.recurrence_by_week
          ? scheduleData.recurrence_by_week.map((day: string) =>
              ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].indexOf(day),
            )
          : [],
      );
    }
  }, [scheduleData]);

  useEffect(() => {
    if (scheduleData?.rule) {
      setStartDate(
        scheduleData.rule.init_date ? dayjs(scheduleData.rule.init_date) : null,
      );
      setStartTime(
        scheduleData.rule.init_hour
          ? dayjs
              .utc(scheduleData.rule.init_hour, "HH:mm:ss")
              .tz(BRAZIL_TIMEZONE)
          : null,
      );
      setDuration(scheduleData.rule.duration || 30);
      setIsRecurrent(scheduleData.rule.is_recurrent || false);
      setRecurrenceFrequency(scheduleData.rule.recurrence_frequency || "DAILY");
      setRecurrenceInterval(scheduleData.rule.recurrence_rule_limit || 1);
      setRecurrenceEnd(
        scheduleData.rule.recurrence_end_rule === "EXACT_DATE"
          ? "DATE"
          : scheduleData.rule.recurrence_end_rule === "AFTER_N_OCCURRENCES"
            ? "COUNT"
            : "NONE",
      );
      setRecurrenceEndDate(
        scheduleData.rule.recurrence_end_date
          ? dayjs(scheduleData.rule.recurrence_end_date)
          : null,
      );
      setRecurrenceCount(scheduleData.rule.recurrence_count || 1);
      setSelectedWeekDays(
        scheduleData.rule.recurrence_by_week
          ? scheduleData.rule.recurrence_by_week.map((day: string) =>
              ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].indexOf(day),
            )
          : [],
      );
    }
  }, [scheduleData]);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate && newDate.isBefore(now, "day")) {
      setStartDate(now);
    } else {
      setStartDate(newDate);
    }
  };

  const handleRecurrenceEndDateChange = (newDate: Dayjs | null) => {
    if (
      newDate &&
      (newDate.isBefore(now, "day") ||
        (startDate && newDate.isBefore(startDate, "day")))
    ) {
      toastError(
        "A data de término deve ser maior que a data atual e a data de início.",
      );
      setRecurrenceEndDate(null);
    } else {
      setRecurrenceEndDate(newDate);
    }
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    if (
      newTime &&
      startDate?.isSame(now, "day") &&
      newTime.isBefore(now, "minute")
    ) {
      setStartTime(now.tz(BRAZIL_TIMEZONE));
    } else {
      setStartTime(newTime?.tz(BRAZIL_TIMEZONE) || null);
    }
  };

  const handleWeekDayToggle = (index: number) => {
    setSelectedWeekDays((prev) =>
      prev.includes(index)
        ? prev.filter((day) => day !== index)
        : [...prev, index],
    );
  };

  const isFormValid = (): boolean => {
    if (!startDate || !startTime || !duration) return false;

    if (isRecurrent) {
      if (!recurrenceFrequency || !recurrenceInterval) return false;

      if (recurrenceFrequency === "WEEKLY" && selectedWeekDays.length === 0) {
        return false;
      }

      if (recurrenceEnd === "DATE") {
        if (!recurrenceEndDate || recurrenceEndDate.isBefore(now, "day")) {
          return false;
        }
        if (
          recurrenceEndDate &&
          startDate &&
          recurrenceEndDate.isBefore(startDate, "day")
        ) {
          return false;
        }
      }

      if (
        recurrenceEnd === "COUNT" &&
        (!recurrenceCount || recurrenceCount < 1)
      ) {
        return false;
      }
    }

    return true;
  };

  const buildPayload = () => {
    const payload: any = {
      event_id: eventId,
      init_date: startDate?.format("YYYY-MM-DD") || null,
      init_hour: startTime?.tz("UTC").format("HH:mm:ss") || null,
      duration: duration || null,
      is_recurrent: isRecurrent,
      recurrence_frequency: isRecurrent ? recurrenceFrequency : null,
      recurrence_rule_limit: isRecurrent ? recurrenceInterval : null,
      recurrence_end_rule:
        isRecurrent && recurrenceEnd === "DATE"
          ? "EXACT_DATE"
          : recurrenceEnd === "COUNT"
            ? "AFTER_N_OCCURRENCES"
            : null,
      recurrence_end_date:
        isRecurrent && recurrenceEnd === "DATE"
          ? recurrenceEndDate?.format("YYYY-MM-DD")
          : null,
      recurrence_count:
        isRecurrent && recurrenceEnd === "COUNT" ? recurrenceCount : null,
      recurrence_by_week:
        recurrenceFrequency === "WEEKLY"
          ? selectedWeekDays.map(
              (day) => ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][day],
            )
          : null,
      recurrence_by_month:
        recurrenceFrequency === "MONTHLY"
          ? monthlyOption === "DAY"
            ? "DAY_RULE"
            : "WEEKDAY_RULE"
          : null,
    };

    Object.keys(payload).forEach(
      (key) => payload[key] === null && delete payload[key],
    );

    return payload;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      toastError("Preencha todos os campos obrigatórios.");
      return;
    }
    const payload = buildPayload();
    onNext(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "1.5rem",
          minHeight: "28rem",
        }}
      >
        <Box
          sx={{
            padding: "0",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box display="flex" gap="1rem">
            <DatePicker
              label="Data de Início"
              value={startDate}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              minDate={now}
            />
            <TimePicker
              label="Horário de Início"
              value={startTime}
              onChange={handleTimeChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
            <TextField
              label="Duração"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              select
              fullWidth
            >
              {generateDurationOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        <RadioGroup
          value={String(isRecurrent)}
          onChange={(e) => setIsRecurrent(e.target.value === "true")}
          row
          sx={{ flexDirection: "column", justifyContent: "start" }}
        >
          <FormControlLabel
            value="false"
            control={
              <Radio
                sx={{
                  color: theme.COLORS.PRIMARY,
                  "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                }}
              />
            }
            label="Sem recorrência"
          />
          <FormControlLabel
            value="true"
            control={
              <Radio
                sx={{
                  color: theme.COLORS.PRIMARY,
                  "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                }}
              />
            }
            label="Com recorrência"
          />
        </RadioGroup>

        {isRecurrent && (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap="1rem"
              flexDirection="row"
            >
              <Typography>Repetir a cada</Typography>
              <TextField
                label=""
                type="number"
                value={recurrenceInterval}
                onChange={(e) => setRecurrenceInterval(Number(e.target.value))}
                InputProps={{ inputProps: { min: 1 } }}
                sx={{
                  maxWidth: "64px",
                  maxHeight: "32px",
                  "& .MuiInputBase-input": { padding: "4px 8px" },
                }}
              />
              <FormControl
                sx={{
                  maxWidth: "64px",
                  maxHeight: "32px",
                  "& .MuiInputBase-input": { padding: "4px 8px" },
                }}
              >
                <Select
                  value={recurrenceFrequency}
                  onChange={(e) => setRecurrenceFrequency(e.target.value)}
                  sx={{
                    fontWeight: 700,
                    color: theme.COLORS.PRIMARY,
                    minWidth: "100px",
                  }}
                >
                  <MenuItem
                    value="DAILY"
                    sx={{
                      fontWeight: 700,
                      color: theme.COLORS.PRIMARY,
                      background: theme.COLORS.BACKGROUND_PRIMARY,
                    }}
                  >
                    Dias
                  </MenuItem>
                  <MenuItem
                    value="WEEKLY"
                    sx={{
                      fontWeight: 700,
                      color: theme.COLORS.PRIMARY,
                      background: theme.COLORS.BACKGROUND_PRIMARY,
                    }}
                  >
                    Semanas
                  </MenuItem>
                  <MenuItem
                    value="MONTHLY"
                    sx={{
                      fontWeight: 700,
                      color: theme.COLORS.PRIMARY,
                      background: theme.COLORS.BACKGROUND_PRIMARY,
                    }}
                  >
                    Meses
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {recurrenceFrequency === "WEEKLY" && (
              <Box display="flex" alignItems="center" gap="1rem">
                <Typography>Repetir</Typography>
                <Box display="flex" gap="0.5rem">
                  {daysOfWeek.map((day, index) => (
                    <Box
                      key={index}
                      onClick={() => handleWeekDayToggle(index)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "24px",
                        height: "24px",
                        borderRadius: "8px",
                        backgroundColor: selectedWeekDays.includes(index)
                          ? theme.COLORS.BLACK
                          : `rgba(0, 0, 0, 0.25)`,
                        color: selectedWeekDays.includes(index)
                          ? theme.COLORS.TEXT_PRIMARY
                          : theme.COLORS.TEXT_PRIMARY,
                        border: selectedWeekDays.includes(index)
                          ? `2px solid ${theme.COLORS.PRIMARY}`
                          : "transparent",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          color: selectedWeekDays.includes(index)
                            ? theme.COLORS.PRIMARY
                            : theme.COLORS.BLACK,
                          backgroundColor: selectedWeekDays.includes(index)
                            ? theme.COLORS.BACKGROUND_SECONDARY
                            : theme.COLORS.PRIMARY,
                        },
                      }}
                    >
                      {day}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {recurrenceFrequency === "MONTHLY" && (
              <>
                {startDate && (
                  <RadioGroup
                    value={monthlyOption}
                    onChange={(e) => setMonthlyOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="DAY"
                      control={
                        <Radio
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                          }}
                        />
                      }
                      label={`Mensal no dia ${startDate ? startDate.date() : "X"} de cada mês`}
                    />
                    <FormControlLabel
                      value="WEEK"
                      control={
                        <Radio
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                          }}
                        />
                      }
                      label={`Mensal na ${startDate ? calculateOrdinal(startDate) : "Y"} ${
                        startDate
                          ? daysOfWeekMap[startDate.format("dddd")]
                          : "Z"
                      }`}
                    />
                  </RadioGroup>
                )}
              </>
            )}
            <Box display="flex">
              <Typography sx={{ marginTop: 2 }}>Finaliza</Typography>
              <Box justifyContent="column">
                <RadioGroup
                  value={recurrenceEnd}
                  onChange={(e) => setRecurrenceEnd(e.target.value)}
                  row
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 4,
                  }}
                >
                  <Box display="flex" justifyContent="row" gap="1rem">
                    <FormControlLabel
                      value="DATE"
                      control={
                        <Radio
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                          }}
                        />
                      }
                      label="Em"
                    />
                    <DatePicker
                      value={recurrenceEndDate}
                      format="DD/MM/YYYY"
                      onChange={handleRecurrenceEndDateChange}
                      disablePast
                      minDate={startDate || now}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            maxHeight: "32px",
                            "& .MuiInputBase-input": { padding: "4px 8px" },
                          },
                        },
                      }}
                      sx={{
                        marginRight: "3rem",
                        marginLeft: -2,
                      }}
                      disabled={recurrenceEnd !== "DATE"}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="row"
                    gap="1rem"
                  >
                    <FormControlLabel
                      value="COUNT"
                      control={
                        <Radio
                          sx={{
                            color: theme.COLORS.PRIMARY,
                            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                          }}
                        />
                      }
                      label="Após"
                    />
                    <TextField
                      label=""
                      type="number"
                      value={recurrenceCount}
                      onChange={(e) => {
                        const value = Math.max(1, Number(e.target.value));
                        setRecurrenceCount(value);
                      }}
                      InputProps={{ inputProps: { min: 1 } }}
                      disabled={recurrenceEnd !== "COUNT"}
                      sx={{
                        marginLeft: -1.2,
                        maxWidth: "64px",
                        maxHeight: "32px",
                        "& .MuiInputBase-input": { padding: "4px 8px" },
                      }}
                    />

                    <Typography>ocorrências</Typography>
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
          </>
        )}

        <Box display="flex" justifyContent="space-between" mt={2} gap="25%">
          <Button variant="outline" onClick={onCancel} fullWidth>
            Cancelar
          </Button>

          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!isFormValid()}
            fullWidth
          >
            {isEditMode ? "Salvar Alterações" : "Participantes"}
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default ScheduleTab;
