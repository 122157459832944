import ImagePersonalAndFamily from "assets/images/dashboards/personal_and_family.jpg";
import ImageLocationKey from "assets/images/dashboards/location_key.jpg";
import ImagePersonalPreferences from "assets/images/dashboards/personal_preferences.jpg";
import ImageProfessionalProfile from "assets/images/dashboards/professional_profile.jpg";
import ImageProfessionalPreferences from "assets/images/dashboards/professional_preferences.jpg";
import ImageCorporateBusiness from "assets/images/dashboards/corporate_business.jpg";
import ImageChallengesPurpose from "assets/images/dashboards/challenges_purpose.jpg";

export const backgroundImages: Record<string, string> = {
  "personal-and-family-profile": ImagePersonalAndFamily,
  "location-key": ImageLocationKey,
  "personal-preferences": ImagePersonalPreferences,
  "professional-profile-and-career": ImageProfessionalProfile,
  "professional-preferences": ImageProfessionalPreferences,
  "corporate-and-business-profile": ImageCorporateBusiness,
  "challenges-purpose-and-legacy": ImageChallengesPurpose,
};

export const getBackgroundImage = (pageName: string) => {
  return backgroundImages[pageName] || "";
};
