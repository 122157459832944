import React, { useState } from "react";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Tooltip,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "components/Text";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import CreateEventModal from "../CreateEventModal";
import { apiRainmakersV2 } from "services/apiService";
import { useToast } from "hooks/useToast";
import { Event } from "../../types/events";
import theme from "styles/theme";

const getNextAvailableSlot = (slots: any[], currentDate: Date) => {
  return (
    slots.find((slot) => new Date(slot.init_datetime) > currentDate) || null
  );
};

const getCurrentOngoingSlot = (slots: any[], currentDate: Date) => {
  return slots.find(
    (slot) =>
      new Date(slot.init_datetime) <= currentDate &&
      new Date(slot.end_datetime) >= currentDate,
  );
};

const Events: React.FC<{ event: Event; onUpdate: () => void }> = ({
  event,
  onUpdate,
}) => {
  const { toastSuccess, toastError } = useToast();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const currentDate = new Date();
  const ongoingSlot = getCurrentOngoingSlot(
    event.schedule?.slots || [],
    currentDate,
  );
  const nextSlot = getNextAvailableSlot(
    event.schedule?.slots || [],
    currentDate,
  );

  const status = ongoingSlot
    ? "Acontecendo"
    : nextSlot
      ? "Previsto"
      : "Concluído";

  const handleEditEvent = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditMode(false);
    setIsModalOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenConfirmCancel = () => {
    setIsConfirmCancelOpen(true);
    handleMenuClose();
  };

  const handleDeleteEvent = async () => {
    try {
      await apiRainmakersV2.delete(`/events/v1/events/${event.id}`);
      toastSuccess("Evento cancelado com sucesso!");
      setIsConfirmCancelOpen(false);
      setIsSuccessOpen(true);
      onUpdate();
    } catch (error: any) {
      console.error("Erro ao cancelar evento:", error?.response || error);
      toastError(
        error.response?.data?.message ||
          "Erro ao cancelar evento. Tente novamente.",
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "0 1rem",
          alignContent: "center",
          minHeight: "3.5rem",
          borderRadius: "0.75rem",
          background: theme.COLORS.BACKGROUND_SECONDARY,
          color: theme.COLORS.TEXT_SECONDARY,
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper": {
              pointerEvents: "auto",
            },
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              flex: "0.15",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                marginRight: "8px",
                backgroundColor:
                  status === "Acontecendo"
                    ? theme.COLORS.PRIMARY
                    : nextSlot
                      ? theme.COLORS.PRIMARY
                      : theme.COLORS.SUCCESS,
                animation:
                  status === "Acontecendo" ? "blinking 1.5s infinite" : "none",
                "@keyframes blinking": {
                  "0%, 100%": { opacity: 1 },
                  "50%": { opacity: 0 },
                },
              }}
            />

            <Tooltip title={event.name} arrow>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                  cursor: "pointer",
                }}
              >
                {event.name.length > 30
                  ? `${event.name.slice(0, 30)}...`
                  : event.name}
              </span>
            </Tooltip>
          </Box>

          <Text textAlign="center" flex={0.15}>
            {status}
          </Text>

          <Text textAlign="center" flex={0.15}>
            {event.schedule?.slots?.[0]?.init_datetime
              ? new Date(
                  event.schedule.slots[0].init_datetime,
                ).toLocaleDateString("pt-BR")
              : "Sem data"}
          </Text>

          <Text textAlign="center" flex={0.15}>
            {nextSlot
              ? new Date(nextSlot.init_datetime).toLocaleDateString("pt-BR")
              : "Sem próxima data"}
          </Text>

          <Text textAlign="center" flex={0.15}>
            {event.participants?.total || 0}
          </Text>

          <Box
            sx={{
              display: "flex",
              flex: "0.10",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon
                sx={{
                  color: theme.COLORS.PRIMARY,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            background: theme.COLORS.BACKGROUND_PRIMARY,
            color: theme.COLORS.TEXT_PRIMARY,
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "2px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.75rem",
            padding: "2px 4px",
            "&:hover": {
              backgroundColor: theme.COLORS.PRIMARY,
              color: theme.COLORS.BLACK,
              borderRadius: "4px",
            },
          },
        }}
      >
        <MenuItem onClick={handleEditEvent}>Editar Evento</MenuItem>
        <MenuItem onClick={handleOpenConfirmCancel}>Cancelar Evento</MenuItem>
      </Menu>

      <GlobalModal
        open={isConfirmCancelOpen}
        title="CONFIRMAÇÃO"
        description={`Deseja realmente cancelar o evento "${event.name}"?`}
        onClose={() => setIsConfirmCancelOpen(false)}
        onCancel={() => setIsConfirmCancelOpen(false)}
        onConfirm={handleDeleteEvent}
        confirmButtonText="Sim"
        cancelButtonText="Não"
      />

      <GlobalModal
        open={isSuccessOpen}
        title="CONFIRMAÇÃO"
        description="Evento cancelado com sucesso!"
        onClose={() => setIsSuccessOpen(false)}
        confirmButtonText="Fechar"
      />

      <CreateEventModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onEventCreated={onUpdate}
        onUpdate={onUpdate}
        isEditMode={true}
        eventId={event.id}
        event={event}
      />
    </>
  );
};

export default Events;
