import React, { useEffect, useState } from "react";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import DashboardsCommunityProfile from "microfrontends/Dashboards/CommunityProfile";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import { useUser } from "global/UserContext";
import * as S from "./styles";

const labels = [
  {
    label: "Desafios ",
    sub_label: "2025",
    key: "tab_challenges_2025",
    pageName: "personal-and-family-profile",
    formId: "655b9645-e138-4c9a-a314-cf5b3663e7c3",
  },
  {
    label: "Desafios ",
    sub_label: "Comerciais",
    key: "tab_challenges_commercial",
    pageName: "personal-preferences",
    formId: "fc772b77-3c56-4f87-8e01-0499f8127b6b",
  },
  {
    label: "Desafios ",
    sub_label: "Financeiros",
    key: "tab_challenges_financial",
    pageName: "professional-profile-and-career",
    formId: "7c95af60-dea3-49bf-a465-ee7f768eaf67",
  },
  {
    label: "Desafios ",
    sub_label: "Tecnologia",
    key: "tab_challenges_technology",
    pageName: "professional-preferences",
    formId: "59f63b3b-3afa-44ba-84c0-df1f667c449a",
  }
];

export const ChallengesDashboard = () => {
  const [activeTab, setActiveTab] = useState(labels[0].key);
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now());
  const { user } = useUser();
  const userId = user?.id;

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changeTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content
        pageName={labels.find((tab) => tab.key === activeTab)?.pageName || ""}
      >
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={labels.map((tab, index) => {
            /*       if (tab.key === "tab_community_key_locations") {
              return (
                <PBIDash
                  key={`${filterKey}-${index}`}
                  reportName="rmkr_key_locations"
                />
              );
            } */

            return (
              <DashboardsCommunityProfile
                key={`${filterKey}-${index}`}
                pageName={tab.pageName}
                formId={tab.formId}
                userId={userId || ""}
              />
            );
          })}
          onChange={(key) => changeTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
