import React, { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Text from "components/Text";
import PaginationComponent from "components/Pagination/Pagination";
import Events from "./EventsCard/Events";
import { Event } from "../types/events";
import theme from "styles/theme";

interface EventListProps {
  events: Event[];
  searchTerm: string;
  onUpdate: () => void;
  loading: boolean;
}

const ITEMS_PER_PAGE = 10;

export const EventList: React.FC<EventListProps> = ({
  events = [],
  searchTerm,
  onUpdate,
  loading,
}) => {
  const [sortBy, setSortBy] = useState<
    "name" | "status" | "firstDate" | "nextDate" | "participants"
  >("name");
  const [isAscending, setIsAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSortToggle = (
    column: "name" | "status" | "firstDate" | "nextDate" | "participants",
  ) => {
    if (sortBy === column) {
      setIsAscending(!isAscending);
    } else {
      setSortBy(column);
      setIsAscending(true);
    }
  };

  const statusOrder: Record<string, number> = {
    Acontecendo: 1,
    Previsto: 2,
    Concluído: 3,
  };

  const filteredAndSortedEvents = events
    .map((event) => ({
      ...event,
      participantsTotal: event.participants?.total ?? 0,
      firstDate: event.schedule?.slots?.[0]?.init_datetime ?? "",
      nextDate: event.schedule?.slots?.[1]?.init_datetime ?? "",
      status: event.schedule?.slots?.[0]?.init_datetime
        ? new Date(event.schedule.slots[0].init_datetime) > new Date()
          ? "Previsto"
          : "Acontecendo"
        : "Concluído",
    }))
    .filter((event) =>
      event.name?.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      let valueA: string | number = "";
      let valueB: string | number = "";

      if (sortBy === "name") {
        valueA = a.name || "";
        valueB = b.name || "";
        return isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      if (sortBy === "status") {
        valueA = statusOrder[a.status as keyof typeof statusOrder] ?? 3;
        valueB = statusOrder[b.status as keyof typeof statusOrder] ?? 3;
        return isAscending ? valueA - valueB : valueB - valueA;
      }

      if (sortBy === "firstDate" || sortBy === "nextDate") {
        valueA = a[sortBy] ? new Date(a[sortBy]).getTime() : 0;
        valueB = b[sortBy] ? new Date(b[sortBy]).getTime() : 0;
        return isAscending ? valueA - valueB : valueB - valueA;
      }

      if (sortBy === "participants") {
        valueA = a.participantsTotal;
        valueB = b.participantsTotal;
        return isAscending ? valueA - valueB : valueB - valueA;
      }

      return 0;
    });

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedEvents = filteredAndSortedEvents.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 0.5rem",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          color: theme.COLORS.TEXT_PRIMARY,
          borderRadius: "0.25rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0.16",
            cursor: "pointer",
          }}
          onClick={() => handleSortToggle("name")}
        >
          <Typography variant="caption">Nome</Typography>
          <IconButton size="small">
            {sortBy === "name" &&
              (isAscending ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              ))}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0.15",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => handleSortToggle("status")}
        >
          <Typography variant="caption">Status</Typography>
          <IconButton size="small">
            {sortBy === "status" &&
              (isAscending ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              ))}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0.15",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => handleSortToggle("firstDate")}
        >
          <Typography variant="caption">Data do Primeiro Evento</Typography>
          <IconButton size="small">
            {sortBy === "firstDate" &&
              (isAscending ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              ))}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0.15",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => handleSortToggle("nextDate")}
        >
          <Typography variant="caption">Data do Próximo Evento</Typography>
          <IconButton size="small">
            {sortBy === "nextDate" &&
              (isAscending ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              ))}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0.15",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => handleSortToggle("participants")}
        >
          <Typography variant="caption">Participantes</Typography>
          <IconButton size="small">
            {sortBy === "participants" &&
              (isAscending ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              ))}
          </IconButton>
        </Box>

        <Typography
          variant="caption"
          sx={{ flex: "0.10", textAlign: "center" }}
        >
          Ações
        </Typography>
      </Box>

      <Box sx={{ width: "100%", marginTop: 1 }}>
        {loading ? (
          <Text>Carregando eventos...</Text>
        ) : paginatedEvents.length > 0 ? (
          paginatedEvents.map((event) => (
            <Events key={event.id} event={event} onUpdate={onUpdate} />
          ))
        ) : (
          <Text color="gray">Nenhum evento encontrado.</Text>
        )}
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <PaginationComponent
          totalPages={Math.ceil(
            filteredAndSortedEvents.length / ITEMS_PER_PAGE,
          )}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          size="medium"
          customColor={theme.COLORS.BACKGROUND_SECONDARY}
        />
      </Box>
    </>
  );
};

export default EventList;
