import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { IoInformationCircleOutline } from "react-icons/io5";
import Header from "components/Header";
import Helper from "components/Helper";
import SideBar from "components/SideBar";
import MobileMenu from "components/MobileMenu";
import UserProfileCard from "components/UserProfileCard";
import { RegistrationSuccess } from "pages/Cockpit/components/RegistrationSuccess";
import { useSideBar } from "hooks/useSideBar";
import { useUser } from "global/UserContext";
import { useTab } from "global/TabContext";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import {
  generateSidebarMainMenu,
  generateSidebarMenuForDevice,
} from "utils/sidebarUtils";
import * as S from "./styles";
import theme from "styles/theme";

interface ILayout {
  children: React.ReactNode | React.ReactNode[];
}

const Layout = ({ children }: ILayout) => {
  const { open } = useSideBar();
  const { setUser, token, features, permissions, user } = useUser();
  const { activeTabContext } = useTab();
  const location = useLocation();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const [showUserCard, setShowUserCard] = useState(false);

  const isVisibleCard = activeTabContext === "tab_cockpit";
  const isFreePlan = user?.user_to_organization[0].individual_plan.name === "Plano Educação";
  const isShowcasePage = location.pathname === "/dashboard/education/showcase";

  const handleRedirect = () => {
    window.open('https://rainmakers.algoricca.com/dashboard/education/showcase', '_blank');
  };

  if (!isShowcasePage && isFreePlan) {
    return (
      <S.Main>
        <S.Container>
          <RegistrationSuccess onRedirect={handleRedirect} />
        </S.Container>
      </S.Main>
    );
  }

  const menuItems = generateSidebarMenuForDevice(
    features,
    permissions,
    isMobile || isTablet,
  );

  const mobileMainMenu = generateSidebarMainMenu(
    features,
    permissions,
    isMobile || isTablet,
  );

  const isCommunityPage = location.pathname.includes("community");

  return (
    <S.Main>
      <S.Container>
        {isDesktop && (
          <>
            <SideBar />
            <Header />
            {isVisibleCard ? (
              <S.Box>
                {!showUserCard && (
                  <S.IconButtonContainer>
                    <IconButton
                      onClick={() => setShowUserCard(true)}
                      sx={{ color: theme.COLORS.PRIMARY }}
                    >
                      <IoInformationCircleOutline size={24} />
                    </IconButton>
                  </S.IconButtonContainer>
                )}

                <S.AnimatedWrapper isVisible={showUserCard}>
                  <UserProfileCard onClose={() => setShowUserCard(false)} />
                </S.AnimatedWrapper>

                <S.Wrapper open={false}>
                  <S.Content>{children}</S.Content>
                </S.Wrapper>
              </S.Box>
            ) : (
              <S.Wrapper open={true}>
                <S.Content>{children}</S.Content>
              </S.Wrapper>
            )}
            <Helper />
          </>
        )}

        {isMobile && (
          <>
            <Header />
            <MobileMenu mainMenu={mobileMainMenu} menuItems={menuItems} />
            <S.WrapperMobile open={false}>
              <S.ContentMobile isCommunity={isCommunityPage}>
                {children}
              </S.ContentMobile>
            </S.WrapperMobile>
          </>
        )}

        {isTablet && (
          <>
            <Header />
            <MobileMenu mainMenu={mobileMainMenu} menuItems={menuItems} />
            <S.WrapperMobile open={false}>
              <S.ContentMobile isCommunity={isCommunityPage}>
                {children}
              </S.ContentMobile>
            </S.WrapperMobile>
          </>
        )}
      </S.Container>
    </S.Main>
  );
};

export default Layout;
