"use client";

import React from "react";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiEducationNext } from "microfrontends/config/api";

interface CategoriesProps {
  userId: string;
}

const Categories = (userId: CategoriesProps) => {
  const iframeUrl = `${apiEducationNext}/education-platform/education/admin/categories/${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Education Platform - Categories"
      />
    </div>
  );
};

export default Categories;
