import styled, { css } from "styled-components";
import React from "react";

export enum TextSize {
  XXSmall = "XXSmall",
  xSmall = "xSmall",
  small = "small",
  medium = "medium",
  large = "large",
  xLarge = "xLarge",
}

interface ITextProps {
  children: React.ReactNode;
  size?:
    | TextSize
    | "XXSmall"
    | "xSmall"
    | "small"
    | "medium"
    | "large"
    | "xLarge";
  bold?: boolean;
  color?: keyof typeof CUSTOM_COLOR;
  secondary?: boolean;
  className?: string;
  truncate?: boolean;
  tooltip?: string;
  fontWeight?: number;
  textAlign?: "left" | "center" | "right" | "justify";
  flex?: number;
  underline?: boolean;
  maxWidth?: string | number;
  onClick?: () => void;
}

const CUSTOM_SIZE: Record<TextSize, ReturnType<typeof css>> = {
  [TextSize.XXSmall]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.XXS};
  `,
  [TextSize.xSmall]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.XS};
  `,
  [TextSize.small]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.SM};
  `,
  [TextSize.medium]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.MD};
  `,
  [TextSize.large]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.LG};
  `,
  [TextSize.xLarge]: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.XL};
  `,
};

const CUSTOM_COLOR: Record<string, ReturnType<typeof css>> = {
  white: css`
    color: ${({ theme }) => theme.COLORS.WHITE};
  `,
  black: css`
    color: ${({ theme }) => theme.COLORS.BLACK};
  `,
  gray: css`
    color: ${({ theme }) => theme.COLORS.TEXT_TERTIARY};
  `,
  green: css`
    color: ${({ theme }) => theme.COLORS.TEXT_SUCCESS};
  `,
  red: css`
    color: ${({ theme }) => theme.COLORS.TEXT_ERROR};
  `,
  yellow: css`
    color: ${({ theme }) => theme.COLORS.TEXT_WARNING};
  `,
  primary: css`
    color: ${({ theme }) => theme.COLORS.PRIMARY};
  `,
};

export const ContainerText = styled.p<ITextProps>`
  ${({ size = TextSize.medium }) => CUSTOM_SIZE[size]};
  ${({ color }) => CUSTOM_COLOR[color || "white"]};
  font-weight: ${({ fontWeight, theme, bold }) =>
    fontWeight
      ? fontWeight
      : bold
        ? theme.FONT_WEIGHT.BOLD
        : theme.FONT_WEIGHT.REGULAR};
  font-family: ${({ theme, secondary }) =>
    !secondary ? theme.FONT_FAMILY.PRIMARY : theme.FONT_FAMILY.SECONDARY};
  text-align: ${({ textAlign }) => textAlign || "left"};
  flex: ${({ flex }) => flex || "auto"};
  font-style: normal;
  line-height: normal;
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === "number" ? `${maxWidth}rem` : maxWidth};
  line-height: 1.5;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  ${({ truncate }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
