import React, { useEffect, useState } from "react";
import EducationMyCoursesNextJS from "microfrontends/Education/MyCourses";
import { useTab } from "global/TabContext";
import { useUser } from "global/UserContext";
import * as S from "./styles";

export const EducationMyCourses = () => {
  const [activeTab] = useState("education_my_courses");
  const { setActiveTabContext } = useTab();
  const { user } = useUser();
  const userId = user?.id;

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  return (
    <S.Wrapper>
      <S.Content>
        {userId && <EducationMyCoursesNextJS userId={userId} />}
      </S.Content>
    </S.Wrapper>
  );
};
