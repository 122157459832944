"use client";

import React from "react";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiEducationNext } from "microfrontends/config/api";

interface LessonsProps {
  userId: string;
}
const Lessons = ({ userId }: LessonsProps) => {
  const iframeUrl = `${apiEducationNext}/education-platform/education/admin/lessons/${userId}`;
  //const iframeUrl = `http://localhost:3002/education-platform/education/admin/lessons/${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Education Platform - Lessons"
      />
    </div>
  );
};

export default Lessons;
