"use client";

import React from "react";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiEducationNext } from "microfrontends/config/api";

const Courses = () => {
  const iframeUrl = `${apiEducationNext}/education-platform/education/admin/courses`;
  //const iframeUrl = `http://localhost:3002/education-platform/education/admin/courses`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Education Platform - Courses"
      />
    </div>
  );
};

export default Courses;
