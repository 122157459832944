import styled from "styled-components";
import theme from "styles/theme";

export const PreviewImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  min-height: 500px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: ${theme.COLORS.BACKGROUND_TERTIARY};
  width: auto;
  min-width: 18rem;

  .user-name {
    margin-top: 1rem;
    font-weight: 600;
    word-break: break-word;
    white-space: normal;
    text-align: center;
  }
`;

export const ProfilePictureWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserName = styled.h2`
  font-size: 20px;
  margin: 10px 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0.5rem 0;
  max-height: 2rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const LevelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

export const LevelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UpdateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const AssessmentPropsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ButtonChangePictureStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: "0.8rem",
};

export const ButtonLevelStyle = {
  fontSize: "0.85rem",
  maxWidth: "75px",
  borderRadius: "32px",
  color: `${theme.COLORS.PRIMARY}`,
  borderColor: `${theme.COLORS.PRIMARY}`,
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
    opacity: 0.7,
  },
};

export const ButtonStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  borderColor: `${theme.COLORS.PRIMARY}`,
  backgroundColor: `${theme.COLORS.BACKGROUND_SECONDARY}`,
  marginTop: "10px",
  "&:hover": {
    borderColor: `${theme.COLORS.PRIMARY}`,
    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
  },
};

export const PublishButtonStyle = {
  backgroundColor: `${theme.COLORS.PRIMARY}`,
  color: `${theme.COLORS.BLACK}`,
  marginTop: "10px",
  "&:hover": {
    backgroundColor: `${theme.COLORS.YELLOW_4}`,
  },
};

export const PublishButtonLinkStyle = {
  color: `${theme.COLORS.PRIMARY}`,
  textDecoration: "underline",
  "&:hover": {
    backgroundColor: `${theme.COLORS.YELLOW_4}`,
  },
};

export const ButtonLinkDangerStyle = {
  color: `${theme.COLORS.ERROR}`,
  textDecoration: "underline",
  "&:hover": {
    backgroundColor: `${theme.COLORS.ERROR}`,
    opacity: 0.8,
  },
};

export const IconContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  button {
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const IconButtonContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
`;

export const Content = styled.div`
  flex: 1;
  padding: 1rem;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_PRIMARY};
`;
