import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { apiOrchestrator } from "services/apiService";
import { useNavigate } from "react-router-dom";
import { useToast } from "hooks/useToast";
import {
  UserPermissions,
  Feature,
  PlanFeaturesResponse,
} from "types/userPlanFeaturesTypes";

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  whatsapp_number: string;
  image_url: string;
  user_to_organization: Array<{
    organization: {
      name: string;
      uuid: string;
      plan: {
        name: string;
        uuid: string;
      };
    };
    group_role: {
      name: string;
      uuid: string;
    };
    profile: {
      name: string;
      uuid: string;
    };
    individual_plan: {
      name: string;
      uuid: string;
      description: string;
    };
  }>;
  user_to_teams: any[];
  social_networks: any[];
  person_id: string;
  is_advisor: boolean;
}

interface UserContextProps {
  user: UserData | null;
  setUser: React.Dispatch<React.SetStateAction<UserData | null>>;
  token: string | null;
  setToken: (token: string) => void;
  logout: () => void;
  handleSetUserId: (userId: string) => void;
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
  features: Feature[];
  setFeatures: (features: Feature[]) => void;
  initializeUserContext: (userId: string, token: string) => Promise<void>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserData | null>(() => {
    const storedUser = localStorage.getItem("userData");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState<string | null>(() =>
    localStorage.getItem("AUTH_TOKEN"),
  );

  //const [token, setToken] = useState<string | null>();

  const [userId, setUserId] = useState<string | null>(() =>
    localStorage.getItem("user_id"),
  );
  const [permissions, setPermissions] = useState<UserPermissions>([]);
  const [features, setFeatures] = useState<Feature[]>([]);

  const navigate = useNavigate();
  const { toastSuccess, toastInfo, toastError } = useToast();

  useEffect(() => {
    if (token && userId) {
      apiOrchestrator.defaults.headers.common["Authorization"] =
        `Bearer ${token}`;
    }
  }, [token, userId]);

  const handleSetUserId = (newUserId: string) => {
    setUserId(newUserId);
    localStorage.setItem("user_id", newUserId);
  };

  const fetchUserPermissions = async (userId: string) => {
    const response = await apiOrchestrator.get(`/user-policies/${userId}`);
    const permissions = response.data || [];
    setPermissions(permissions);
    return permissions;
  };

  const fetchPlanFeatures = async (planUuid: string, userUuid: string) => {
    const response = await apiOrchestrator.get<PlanFeaturesResponse>(
      `/plan/features/${planUuid}?user_uuid=${userUuid}`,
    );
    const features = response.data.plan_features || [];
    setFeatures(features);
    return features;
  };

  const initializeUserContext = async (userId: string, token: string) => {
    try {
      setToken(token);
      localStorage.setItem("AUTH_TOKEN", token);

      handleSetUserId(userId);
      apiOrchestrator.defaults.headers.common["Authorization"] =
        `Bearer ${token}`;
      const userData = await apiOrchestrator.get(`/auth/me/${userId}`);
      setUser(userData.data);

      const planUuid =
        userData.data.user_to_organization?.[0]?.organization?.plan?.uuid;
      if (planUuid) {
        await fetchPlanFeatures(planUuid, userId);
      }
      await fetchUserPermissions(userId);
    } catch (error) {
      console.error("Erro ao inicializar o contexto do usuário:", error);
      toastError("Erro ao carregar os dados do usuário.");
      logout();
    }
  };

  const logout = async () => {
    try {
      if (token) {
        await apiOrchestrator.post("/auth/logout", null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } catch (error) {
      console.error("Erro ao realizar logout no Cognito:", error);
      toastError("Erro ao desconectar do sistema remoto.");
    } finally {
      setToken(null);
      setUser(null);
      setUserId(null);
      setPermissions([]);
      setFeatures([]);

      localStorage.removeItem("AUTH_TOKEN");
      localStorage.removeItem("userData");
      localStorage.removeItem("user_id");
      localStorage.removeItem("menuTitle");
      localStorage.removeItem("lastSelectedMenu");

      toastInfo("Você foi desconectado da plataforma.");
      setTimeout(() => navigate("/login", { replace: true }), 400);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("AUTH_TOKEN");
    const storedUserId = localStorage.getItem("user_id");

    if (storedToken && storedUserId) {
      initializeUserContext(storedUserId, storedToken).catch(() => {
        logout();
      });
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        logout,
        handleSetUserId,
        permissions,
        setPermissions,
        features,
        setFeatures,
        initializeUserContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser deve ser usado dentro de um UserProvider");
  }
  return context;
};
