import React, { useEffect, useState } from "react";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import DashboardsCommunityProfile from "microfrontends/Dashboards/CommunityProfile";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import { useUser } from "global/UserContext";
import * as S from "./styles";

const labels = [
  {
    label: "Perfil Pessoal ",
    sub_label: "e Familiar",
    key: "tab_community_profile_personal_and_family",
    pageName: "personal-and-family-profile",
    formId: "372e716e-e8a9-486c-b782-4abba4993a44",
  },
  /*   {
    label: "Localidades ",
    sub_label: "Chave",
    key: "tab_community_key_locations",
    pageName: "location-key",
    formId: "1ae705f1-b20b-467e-9690-b43acf766b63",
  }, */
  {
    label: "Preferências ",
    sub_label: "Pessoais",
    key: "tab_community_profile_personal_preferences",
    pageName: "personal-preferences",
    formId: "2f8eebf4-b7cc-4394-8f83-b8157bad8b1c",
  },
  {
    label: "Perfil Profissional ",
    sub_label: "e Carreira",
    key: "tab_community_professional_profile_and_career",
    pageName: "professional-profile-and-career",
    formId: "658dbf8c-5442-4dd0-838b-9d0be478b8e8",
  },
  {
    label: "Preferências ",
    sub_label: "Profissionais",
    key: "tab_community_profile_professional_preferences",
    pageName: "professional-preferences",
    formId: "cdcb158c-db72-4c98-a1cf-15dae7058fdd",
  },
  {
    label: "Perfil Empresarial ",
    sub_label: "e de Negócios",
    key: "tab_community_profile_corporate_and_business",
    pageName: "corporate-and-business-profile",
    formId: "a9781d78-b8ce-4f20-8b00-fc3638ff5f09",
  },
  {
    label: "Desafios",
    sub_label: "Propósito e Legado",
    key: "tab_voter_diagnosis_profile_challenges",
    pageName: "challenges-purpose-and-legacy",
    formId: "0746cdfb-7cf7-4597-b686-1ad8f33eb6e9",
  },
];

export const CommunityProfile = () => {
  const [activeTab, setActiveTab] = useState(labels[0].key);
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now());
  const { user } = useUser();
  const userId = user?.id;

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changeTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content
        pageName={labels.find((tab) => tab.key === activeTab)?.pageName || ""}
      >
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={labels.map((tab, index) => {
            /*       if (tab.key === "tab_community_key_locations") {
              return (
                <PBIDash
                  key={`${filterKey}-${index}`}
                  reportName="rmkr_key_locations"
                />
              );
            } */

            return (
              <DashboardsCommunityProfile
                key={`${filterKey}-${index}`}
                pageName={tab.pageName}
                formId={tab.formId}
                userId={userId || ""}
              />
            );
          })}
          onChange={(key) => changeTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
