import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import { apiRainmakersV2 } from "services/apiService";
import Button from "components/Button";
import theme from "styles/theme";

interface EventDataTabProps {
  data: {
    name: string;
    description: string;
    type_code?: string;
    type?: string;
    is_exclusive: boolean;
  };
  isEditMode: boolean;
  onNext: (values: {
    name: string;
    description: string;
    type_code: string;
    type?: string;
    is_exclusive: boolean;
  }) => Promise<void> | void;
  onCancel: () => void;
}

interface EventType {
  code: string;
  name: string;
}

const EventDataTab: React.FC<EventDataTabProps> = ({
  data,
  isEditMode = false,
  onNext,
  onCancel,
}) => {
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const response = await apiRainmakersV2.get("/events/v1/types/");
        setEventTypes(response.data);
      } catch (error) {
        console.error("Erro ao buscar tipos de evento:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventTypes();
  }, []);

  return (
    <Formik
      initialValues={{
        name: data.name || "",
        description: data.description || "",
        type_code: data.type_code || "",
        is_exclusive: data.is_exclusive || false,
      }}
      validate={(values) => {
        const errors: Partial<{
          name: string;
          description: string;
          type_code: string;
          is_exclusive: string;
        }> = {};
        if (!values.name) errors.name = "O nome do evento é obrigatório";
        if (!values.description)
          errors.description = "A descrição é obrigatória";
        if (!values.type_code) errors.type_code = "Selecione o tipo do evento";
        if (values.is_exclusive === undefined)
          errors.is_exclusive = "Escolha uma opção";
        return errors;
      }}
      onSubmit={(values) => {
        onNext(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        dirty,
      }) => (
        <Form>
          <Box
            sx={{
              width: "100%",
              minHeight: "28rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              gap: "1rem",
              padding: 0,
            }}
          >
            <TextField
              label="Nome do Evento"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              sx={{ marginTop: "1em" }}
            />
            <TextField
              label="Descrição"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              label="Selecionar Tipo de Evento"
              name="type_code"
              value={values.type_code}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.type_code && Boolean(errors.type_code)}
              helperText={touched.type_code && errors.type_code}
              select
              fullWidth
              SelectProps={{ native: false }}
              disabled={loading}
            >
              <MenuItem value="" disabled>
                {loading
                  ? "Carregando tipos de evento..."
                  : "Selecione o tipo de evento"}
              </MenuItem>
              {eventTypes.map((type) => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
            <RadioGroup
              name="is_exclusive"
              value={String(values.is_exclusive)}
              onChange={handleChange}
              row
              sx={{ marginLeft: "1rem" }}
            >
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    sx={{
                      color: theme.COLORS.PRIMARY,
                      "&.Mui-checked": {
                        color: theme.COLORS.PRIMARY,
                      },
                    }}
                  />
                }
                label="Evento exclusivo"
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    sx={{
                      color: theme.COLORS.PRIMARY,
                      "&.Mui-checked": {
                        color: theme.COLORS.PRIMARY,
                      },
                    }}
                  />
                }
                label="Evento aberto a todos"
              />
            </RadioGroup>
            {touched.is_exclusive && Boolean(errors.is_exclusive) && (
              <span style={{ color: theme.COLORS.ERROR }}>
                {errors.is_exclusive}
              </span>
            )}

            <Box display="flex" justifyContent="space-between" mt={2} gap="25%">
              <Button
                variant="outline"
                color="secondary"
                onClick={onCancel}
                fullWidth
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                color="primary"
                type="submit"
                fullWidth
                disabled={!isValid || !dirty}
              >
                {isEditMode ? "Salvar Alterações" : "Local"}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EventDataTab;
