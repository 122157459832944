import React, { useEffect } from "react";
import ImageCarousel from "pages/Cockpit/components/ImagesCarousel";
import Agenda from "pages/Cockpit/components/Agenda";
import MobileCockpit from "./MobileCockpit";
import { useTab } from "global/TabContext";
import { useUser } from "global/UserContext";
import { useMenuTitle } from "hooks/useMenuTitleContext";
import { useEvents } from "pages/Cockpit/components/Agenda/hooks/useEvents";
import { RegistrationSuccess } from './components/RegistrationSuccess';
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import * as S from "./styles";

export const Cockpit: React.FC = () => {
  const { events, eventsToday, loading, error } = useEvents();
  const { user } = useUser();
  const [activeTab] = React.useState("tab_cockpit");
  const { setActiveTabContext } = useTab();
  const { updateMenuTitle } = useMenuTitle();

  useEffect(() => {
    setActiveTabContext(activeTab);
    updateMenuTitle("Cockpit");
  }, [activeTab, setActiveTabContext]);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const isFreePlan = user?.user_to_organization[0].individual_plan.name === "Plano Educação";
  
  const handleRedirect = () => {
    window.open('https://rainmakers.algoricca.com/dashboard/education/showcase', '_blank');
  };

  if (isFreePlan) {
    return <RegistrationSuccess onRedirect={handleRedirect} />;
  }

  return isMobile || isTablet ? (
    <S.MobileSection>
      <MobileCockpit events={eventsToday} />
    </S.MobileSection>
  ) : (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Section>
            <ImageCarousel />
          </S.Section>
          <S.Section>
            <Agenda />
          </S.Section>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
