import { useEffect, useRef, useState } from "react";

const useHasTextOverflow = (text: string) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const checkOverflow = () => {
      const isOverflowing = element.scrollWidth > element.clientWidth;
      setHasOverflow(isOverflowing);
    };

    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(element);

    checkOverflow();

    return () => resizeObserver.disconnect();
  }, [text]);

  return { ref, hasOverflow };
};

export default useHasTextOverflow;
