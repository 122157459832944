import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import Text from "components/Text";
import { Box } from "@mui/material";
import { Avatar } from "antd";
import Button from "components/Button";
import { RawEvent } from "../types/agendaTypes";
import theme from "styles/theme";

interface DetailsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmOccurrence: () => void;
  onConfirmSeries: () => void;
  event: any;
  correspondingEvent?: RawEvent;
  eventStatus?: "PENDING" | "ACCEPTED" | "DECLINED" | "PARTIAL";
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  onClose,
  onConfirmOccurrence,
  onConfirmSeries,
  event,
  correspondingEvent,
  eventStatus,
}) => {
  const BulletPoint: React.FC = () => (
    <FiberManualRecordIcon
      sx={{
        fontSize: "0.75rem",
        color: theme.COLORS.PRIMARY,
        marginRight: "0.25rem",
      }}
    />
  );

  return (
    <GlobalModal
      open={open}
      title="DETALHES DO EVENTO"
      description=""
      onClose={onClose}
      width="40rem"
      height="auto"
      closeOnEscapeKeyDown
      closeOnBackdropClick
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Text size="medium" bold color="primary">
            Dados do Evento
          </Text>
          <Text size="medium" bold>
            {event.title}
          </Text>
          <Text size="medium">{correspondingEvent?.description}</Text>
          <Box
            sx={{
              display: "flex",
              alignSelf: "start",
              gap: 4,
            }}
          >
            {correspondingEvent?.type && (
              <Text>
                <BulletPoint /> {correspondingEvent?.type.name}
              </Text>
            )}
            {correspondingEvent?.is_exclusive && (
              <Text>
                <BulletPoint /> Evento Exclusivo
              </Text>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Text size="medium" bold color="primary">
            Local{" "}
          </Text>

          <Text size="medium">
            <BulletPoint />
            {event.location}
          </Text>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Text size="medium" bold color="primary">
            Agendamento
          </Text>
          <Box display="flex" gap="2rem" alignSelf="start">
            <Text size="medium" bold>
              {event.date} - {event.time}
            </Text>
            <Text size="medium">Duração: {event.duration}</Text>
          </Box>
          {event.scheduleRuleDescription === "Evento único" ? (
            <>
              <Text color="primary">
                <BulletPoint /> Sem recorrencia.
              </Text>
            </>
          ) : (
            <>
              <Text color="primary">
                <BulletPoint /> Com recorrência:
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1.5rem",
                  gap: 0.5,
                }}
              >
                <Text size="medium">{event.scheduleRuleDescription}</Text>
                <Text size="medium">
                  {correspondingEvent?.schedule.rule.recurrence_end_rule ===
                  "AFTER_N_OCCURRENCES" ? (
                    `Finaliza após ${correspondingEvent?.schedule.rule.recurrence_rule_limit} ocorrências.`
                  ) : correspondingEvent?.schedule.rule.recurrence_end_rule ===
                    "EXACT_DATE" ? (
                    <>
                      Finaliza em{" "}
                      <strong>
                        {correspondingEvent?.schedule.rule.recurrence_end_date
                          ?.split("-")
                          .reverse()
                          .join("/")}
                      </strong>
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            maxWidth: "32rem",
            marginBottom: 2,
          }}
        >
          {event.coordinator && (
            <Box>
              <Text size="medium" bold color="primary">
                Coordenador
              </Text>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 1,
                  mt: 0.5,
                }}
              >
                <Avatar
                  size="small"
                  src={event.coordinator.photoUrl}
                  alt={event.coordinator.firstName}
                />
                <Text
                  size="medium"
                  truncate
                  tooltip={`${event.coordinator.firstName} ${event.coordinator.lastName}`}
                >
                  {event.coordinator.firstName} {event.coordinator.lastName}
                </Text>
              </Box>
            </Box>
          )}

          {event.presenters && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: 0.5,
              }}
            >
              <Text size="medium" bold color="primary">
                Palestrantes
              </Text>

              {Array.isArray(event.presenters) &&
                event.presenters.map((presenter: any, index: any) => (
                  <Box
                    key={presenter.uuid}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      size="small"
                      src={presenter.photoUrl}
                      alt={presenter.firstName}
                    />
                    <Text
                      size="medium"
                      truncate
                      tooltip={`${presenter.firstName} ${presenter.lastName}`}
                    >
                      {presenter.firstName} {presenter.lastName}
                    </Text>
                  </Box>
                ))}
            </Box>
          )}
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Text size="medium" bold color="primary">
            Patrocinador
          </Text>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Text size="medium" bold color="primary">
            Documentos de Apoio
          </Text>
        </Box> */}
      </Box>

      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "sticky",
          bottom: 0,
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {event.status === "ACCEPTED" || event.status === "PARTIAL" ? (
          <Button
            onClick={onClose}
            variant="outline"
            style={{ width: "16rem" }}
          >
            Fechar
          </Button>
        ) : (
          <Box
            width="100%"
            maxWidth="40rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="1.5rem"
          >
            <Button
              onClick={onConfirmOccurrence}
              fullWidth
              style={{ maxWidth: "16rem" }}
            >
              Responder a Ocorrência
            </Button>
            <Button
              onClick={onConfirmSeries}
              fullWidth
              style={{ maxWidth: "16rem" }}
            >
              Responder a Série
            </Button>
          </Box>
        )}
      </Box>
    </GlobalModal>
  );
};

export default DetailsModal;
