import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_SECONDARY};
`;

export const SuccessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_QUATERNARY};
  border-radius: 16px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .text-title {
    color: ${({ theme }) => theme.COLORS.PRIMARY};
    margin-bottom: 1.5rem;
  }
`;

export const Image = styled.img`
  max-width: 200px;
  margin-bottom: 2rem;
`;

export const SuccessMessage = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ThankYouMessage = styled.div`
  margin-top: 2rem;
  color: ${({ theme }) => theme.COLORS.PRIMARY};
`;

export const LinkText = styled.span`
  color: ${({ theme }) => theme.COLORS.PRIMARY};
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`; 