import styled from "styled-components";
import BackgroundImage from "assets/images/header/new-background-header.png";
import theme from "styles/theme";

export const HeaderContainer = styled.header<any>`
  background-color: ${({ isMobile, theme }) =>
    isMobile ? "transparent" : theme.COLORS.BACKGROUND_SECONDARY};
  background-image: ${({ isMobile }) =>
    !isMobile ? `url(${BackgroundImage})` : "none"};
  background-size: cover;
  background-position: center;
  color: white;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ isMobile }) => (isMobile ? "1rem" : "9rem")};
  transition:
    padding-left 0.3s,
    background-color 0.3s,
    background-image 0.3s;
  padding-right: 2.25rem;
`;

export const MobileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const TitleText = styled.h1`
  color: ${theme.COLORS.WHITE_3};
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: right;
  margin: 0;
  color: white;
`;

export const LogoHeaderImage = styled.img<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "2.5rem" : "10rem")};
`;
