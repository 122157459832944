import React, { useState } from "react";
import { Box, Card, CardContent, CardActions } from "@mui/material";
import theme from "styles/theme";
import Text from "components/Text";
import Button from "components/Button";
import EventInfo from "./EventInfo";
import DetailsModal from "./DetailsModal";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { useUser } from "global/UserContext";
import { apiRainmakersV2 } from "services/apiService";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import { RawEvent } from "../types/agendaTypes";

interface EventCardProps {
  nextEvent: any;
  correspondingEvent?: RawEvent;
  refreshEvents: () => void;
}
const EventCard: React.FC<EventCardProps> = ({
  nextEvent,
  correspondingEvent,
  refreshEvents,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const { user } = useUser();
  const userId = user?.id;
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isMeetingModalOpen, setMeetingModalOpen] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [eventStatus, setEventStatus] = useState(nextEvent.status);
  const [eventType, setEventType] = useState("");

  const MAX_VISIBLE_PRESENTERS = 2;
  const presenters = nextEvent.presenters || [];
  const hasMorePresenters = presenters.length > MAX_VISIBLE_PRESENTERS;

  const fullPresentersList = presenters
    .map((p: any) => `${p.firstName} ${p.lastName}`)
    .join(", ");

  const handleRespondToEvent = async (status: string) => {
    try {
      let payload: Array<{ init_datetime: string; end_datetime: string }> = [];

      if (eventType === "OCCURRENCE") {
        payload = [
          {
            init_datetime: nextEvent.initDatetime,
            end_datetime: nextEvent.endDatetime,
          },
        ];
      } else if (
        correspondingEvent &&
        correspondingEvent.schedule &&
        correspondingEvent.schedule.slots &&
        correspondingEvent.schedule.slots.length > 0
      ) {
        payload = correspondingEvent.schedule.slots.map((slot: any) => ({
          init_datetime: slot.init_datetime,
          end_datetime: slot.end_datetime,
        }));
      }

      if (payload.length === 0) {
        console.error("Erro: Dados do evento não disponíveis.");
        return;
      }

      const endpoint = `/events/v1/user-schedules/respond/${nextEvent.id}/${userId}?status=${status}`;
      await apiRainmakersV2.post(endpoint, payload);

      console.log(`Respondido com sucesso! Status: ${status}`);
      setEventStatus(status);
      refreshEvents();
    } catch (error) {
      console.error("Erro ao responder ao evento:", error);
      setErrorMessage("Erro ao processar a solicitação.");
      setErrorModalOpen(true);
    }
  };

  const handleConfirmAction = async () => {
    const status = eventType === "OCCURRENCE" ? "PARTIAL" : "ACCEPTED";
    await handleRespondToEvent(status);
    setConfirmModalOpen(false);
    setDetailsModalOpen(false);
  };

  const handleCancelAction = async () => {
    const status = "DECLINED";
    await handleRespondToEvent(status);
    setConfirmModalOpen(false);
    setDetailsModalOpen(false);
  };

  const getButtonLabel = () => {
    switch (eventStatus) {
      case "ACCEPTED":
        return nextEvent.is_online ? "Acessar Sala" : "Ver Detalhes";
      case "PARTIAL":
        return nextEvent.is_online ? "Acessar Sala" : "Ver Detalhes";
      case "DECLINED":
        return "Convite Recusado";
      default:
        return "Responder Convite";
    }
  };

  const isButtonDisabled = () => {
    if (eventStatus === "ACCEPTED" || eventStatus === "PARTIAL") {
      if (nextEvent.is_online) {
        return !nextEvent.isOngoing;
      }
      return false;
    }
    return false;
  };

  const handleButtonClick = () => {
    if (eventStatus === "ACCEPTED") {
      console.log("Exibir detalhes ou acessar sala");
      return;
    }

    setDetailsModalOpen(true);
  };

  const handleButtonOccurrenceClick = () => {
    setEventType("OCCURRENCE");
    setConfirmModalOpen(true);
  };

  const handleButtonSeriesClick = () => {
    setEventType("SERIES");
    setConfirmModalOpen(true);
  };

  const handleMeetingConfirm = async () => {
    setMeetingModalOpen(false);

    if (!nextEvent?.room?.room_data || !nextEvent?.room?.participation_status) {
      setErrorMessage(
        "Sala de reunião inválida ou participação não autorizada.",
      );
      setErrorModalOpen(true);
      return;
    }

    try {
      const { room_data } = nextEvent.room;

      if (!room_data.id || !room_data.vendor_room_id || !room_data.room_pwd) {
        setErrorMessage("Dados incompletos da sala de reunião.");
        setErrorModalOpen(true);
        return;
      }

      const iframeUrl = `${process.env.REACT_APP_URL_MEETING_PLATFORM}/room?user=${userId}&meeting=${room_data.vendor_room_id}&pwd=${room_data.room_pwd}`;

      console.log("URL da sala de reunião:", iframeUrl);

      const openMeetingWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = screenWidth / 2 - 500;
        const top = screenHeight / 2 - 225;

        const windowFeatures = `toolbar=no, menubar=no, width=1000, height=450, top=${top}, left=${left}`;

        window.open(iframeUrl, "_blank", windowFeatures);
      };

      if (iframeUrl) {
        openMeetingWindow();
      } else {
        setErrorMessage("Erro ao gerar o URL da reunião.");
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao abrir a reunião:", error);
      setErrorMessage("Ocorreu um erro inesperado ao abrir a reunião.");
      setErrorModalOpen(true);
    } finally {
      await refreshEvents();
    }
  };

  return (
    <>
      <Card
        sx={{
          background: theme.COLORS.BACKGROUND_PRIMARY,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: 2,
          width: "20rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Text size="small" bold truncate tooltip={nextEvent.title}>
            {nextEvent.title}
          </Text>

          <Text size="small" truncate tooltip={nextEvent.location}>
            {nextEvent.location}
          </Text>

          {nextEvent.coordinator && (
            <Text
              size="small"
              truncate
              tooltip={`Coordenador: ${nextEvent.coordinator.firstName} ${nextEvent.coordinator.lastName}`}
            >
              Coordenador: {nextEvent.coordinator.firstName}{" "}
              {nextEvent.coordinator.lastName}
            </Text>
          )}

          <Box
            sx={{
              display: "flex",
            }}
          >
            <span
              style={{
                color: theme.COLORS.TEXT_PRIMARY,
                fontFamily: "Roboto",
                fontSize: "0.95rem",
                marginRight: "0.25rem",
                fontWeight: 400,
              }}
            >
              {nextEvent.presenters.length > 1
                ? `Palestrantes: `
                : `Palestrante: `}
            </span>

            {nextEvent.presenters
              .slice(0, MAX_VISIBLE_PRESENTERS)
              .map((presenter: any, index: number) => (
                <Box key={presenter.uuid} sx={{ display: "flex", gap: 1 }}>
                  <Text
                    size="small"
                    truncate
                    tooltip={`${presenter.firstName} ${presenter.lastName}`}
                  >
                    {presenter.firstName} {presenter.lastName}
                  </Text>
                </Box>
              ))}
            {hasMorePresenters && (
              <Text
                size="small"
                color="gray"
                isTooltip
                tooltip={`Palestrantes: ${fullPresentersList}`}
              >
                +{presenters.length - MAX_VISIBLE_PRESENTERS}
              </Text>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "0.5rem",
            }}
          >
            <Text
              size="xLarge"
              color="primary"
              bold
              truncate
              tooltip={nextEvent.time}
            >
              {nextEvent.isOngoing
                ? `Acontecendo`
                : `${nextEvent.date} - ${nextEvent.time}`}
            </Text>
            <EventInfo
              totalParticipants={nextEvent.totalParticipants}
              duration={nextEvent.duration}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            style={{
              height: "2.25rem",
              marginLeft: "20%",
              marginRight: "20%",
              marginBottom: "0.25rem",
            }}
            disabled={isButtonDisabled()}
            onClick={handleButtonClick}
          >
            {getButtonLabel()}
          </Button>
        </CardActions>
      </Card>

      <DetailsModal
        open={isDetailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        onConfirmOccurrence={handleButtonOccurrenceClick}
        onConfirmSeries={handleButtonSeriesClick}
        event={nextEvent}
        correspondingEvent={correspondingEvent}
      />

      <GlobalModal
        open={isConfirmModalOpen}
        title="CONFIRMAÇÃO"
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
        onClose={() => {
          setConfirmModalOpen(false);
          setDetailsModalOpen(false);
        }}
        confirmButtonText="Aceitar"
        cancelButtonText="Recusar"
        width="30rem"
        height="15rem"
        closeOnBackdropClick
        closeOnEscapeKeyDown
      >
        <Text size="medium">
          Deseja aceitar ou recusar a{" "}
          {eventType === "OCCURRENCE"
            ? `próxima ocorrência?`
            : `série do evento?`}
        </Text>
        <Box sx={{ marginTop: "1rem" }}>
          <Text size="medium" color="primary" bold>
            {eventType === "OCCURRENCE"
              ? `Próxima ocorrência? ${nextEvent.date} - ${nextEvent.time}`
              : `Recorrência ${nextEvent.scheduleRuleDescription}`}{" "}
            {}
          </Text>
        </Box>
      </GlobalModal>

      <GlobalModal
        open={isMeetingModalOpen}
        title="CONFIRMAÇÃO"
        onConfirm={handleMeetingConfirm}
        onCancel={() => setMeetingModalOpen(false)}
        onClose={() => setMeetingModalOpen(false)}
        confirmButtonText="Entrar"
        cancelButtonText="Cancelar"
        width="30rem"
        height="15rem"
      >
        <Text size="small">Você está prestes a entrar na reunião:</Text>
        <Box sx={{ marginTop: "1rem" }}>
          <Text size="medium" bold>
            {nextEvent.title}
          </Text>
          <Text size="small" color="primary">
            {nextEvent.location}
          </Text>
          <Text size="small" color="primary">
            Data: {nextEvent.date} - {nextEvent.time}
          </Text>
        </Box>
      </GlobalModal>

      <GlobalModal
        open={isErrorModalOpen}
        title="Erro"
        onClose={() => setErrorModalOpen(false)}
        width="30rem"
        height="15rem"
        confirmButtonText="Fechar"
        onConfirm={() => setErrorModalOpen(false)}
      >
        <Text size="medium" color="red" bold>
          {errorMessage}
        </Text>
      </GlobalModal>
    </>
  );
};

export default EventCard;
