import React, { useEffect, useState } from "react";
import { TextField, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { usePersonalInfo } from "./hooks/usePersonalInfo";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Spin from "components/Spin";
import Text from "components/Text";
import theme from "styles/theme";
import _ from "lodash";
import * as S from "./styles";

const PersonalInfoForm: React.FC = () => {
  const { control, handleSubmit, errors, updatePersonalInfo, watch, loading } =
    usePersonalInfo();

  const formValues = watch();
  const [isEdited, setIsEdited] = useState(false);

  const debouncedSubmit = _.debounce((values) => {
    if (isEdited) {
      handleSubmit(() => updatePersonalInfo(values))();
      setIsEdited(false);
    }
  }, 3000);

  useEffect(() => {
    if (isEdited) {
      debouncedSubmit(formValues);
    }

    return () => {
      debouncedSubmit.cancel();
    };
  }, [formValues, isEdited]);

  const handleFieldChange = () => {
    setIsEdited(true);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <S.FormContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome"
                variant="outlined"
                required
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                onChange={(e) => {
                  field.onChange(e);
                  handleFieldChange();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Sobrenome"
                variant="outlined"
                required
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                onChange={(e) => {
                  field.onChange(e);
                  handleFieldChange();
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            name="whatsapp"
            control={control}
            disabled
            render={({ field }) => (
              <PhoneInput
                {...field}
                disabled
                country={"br"}
                inputStyle={{
                  width: "100%",
                  height: "4.2rem",
                  fontSize: "1rem",
                  backgroundColor: theme.COLORS.BACKGROUND_QUATERNARY,
                  color: field.disabled
                    ? theme.COLORS.GRAY_40
                    : theme.COLORS.GRAY_10,
                  border: field.disabled
                    ? `1px solid ${theme.COLORS.GRAY_40}`
                    : `1px solid ${
                        errors.whatsapp
                          ? theme.COLORS.ERROR
                          : theme.COLORS.GRAY_40
                      }`,
                  borderRadius: "4px",
                }}
                buttonStyle={{
                  cursor: field.disabled ? "not-allowed" : "auto",
                  backgroundColor: theme.COLORS.BACKGROUND_QUATERNARY,
                  border: field.disabled
                    ? `1px solid ${theme.COLORS.GRAY_40}`
                    : `1px solid ${theme.COLORS.GRAY_40}`,
                }}
                placeholder="Digite seu WhatsApp"
                specialLabel=""
                value={field.value || ""}
                onChange={(value: string) => {
                  field.onChange(value);
                  handleFieldChange();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            disabled
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                //label="Seu melhor email"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </S.FormContainer>
  );
};

export default PersonalInfoForm;
